import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { sharedUrls } from './../shared/base-url';

@Injectable({
  providedIn: 'root'
})
export class PropertiesService {
  apiUrl: string;
  imagesUrl: string;

  constructor (
    private httpClient: HttpClient
  ) {
    const baseUrl = environment.baseUrl;
    this.apiUrl = `${baseUrl}${sharedUrls.apiUrl}`;
    this.imagesUrl = environment.imagesUrl;
  }

  getPropertiesTypes() {
    const url = `${this.apiUrl}/properties-types`;
    return this.httpClient.get(url);
  }

  getPropertiesUses() {
    const url = `${this.apiUrl}/properties-uses`;
    return this.httpClient.get(url);
  }

  getProperties(filters) {
    const url = `${this.apiUrl}/properties`;
    let json = JSON.stringify(filters);
    let params = new HttpParams().set('json', json);

    return this.httpClient.get(url, { params: params });
  }

  getProperty(id) {
    const datetime = new Date().getTime();
    const url = `${this.apiUrl}/property/${id}?time=${datetime}`;
    return this.httpClient.get(url);
  }

  getAgent(type, id) {
    const url = `${this.apiUrl}/agent`;
    const agent = {
      type: type,
      id: id
    };
    let json = JSON.stringify(agent);
    let params = new HttpParams().set('json', json);
    return this.httpClient.get(url, { params: params });
  }

  saveProperty(data) {
    const url = !data.propertyId ? `${this.apiUrl}/save-property` : `${this.apiUrl}/update-property`;
    let json = JSON.stringify(data);
    let params = new HttpParams().set('json', json);
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    if (!data.propertyId) {
      return this.httpClient.post(url, params, { headers: headers });
    }
    return this.httpClient.put(url, params, { headers: headers });
  }

  updateProperty(data) {
    const url = `${this.apiUrl}/update-property`;
    let json = JSON.stringify(data);
    let params = new HttpParams().set('json', json);
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.httpClient.put(url, params, { headers: headers });
  }

  deleteProperty(id) {
    const url = `${this.apiUrl}/delete-property/${id}`;
    return this.httpClient.delete(url);
  }

  setPropertyImage(propertyId: number, path: string) {
    const modifiedPath = path.replace(/\//g, '.:.');
    const url = `${this.apiUrl}/property/${propertyId}/default-photo/${modifiedPath}`;
    let params = new HttpParams();
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.httpClient.put(url, params, { headers});
  }

  deletePropertyImages(propertyId: number, path: string) {
    const modifiedPath = path.replace(/\//g, '.:.');
    const url = `${this.apiUrl}/property/${propertyId}/photo/${modifiedPath}`;
    return this.httpClient.delete(url);
  }

  fileRequest(propertyId: number, files: Array<File>) {
    const url = `${this.apiUrl}/upload-images`;
    // const imagesUrl = this.imagesUrl;
    return new Promise((resolve, reject) => {
      var formData: any = new FormData();
      var xhr = new XMLHttpRequest();

      for (let i = 0; i < files.length; i++) {
        formData.append('image[]', files[i], files[i].name);
      }
      formData.append('route', `properties/property_${propertyId}`);

      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      }

      xhr.open("POST", url, true);
      xhr.send(formData);
    });
  }

  savePropertyImages(data) {
    const url = `${this.apiUrl}/save-property-images`;
    let json = JSON.stringify(data);
    let params = new HttpParams().set('json', json);
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.httpClient.post(url, params, { headers: headers });
  }
}
