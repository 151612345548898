import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavSideBarService } from 'src/app/nav-side-bar/nav-side-bar.service';
import { StorageService } from 'src/app/storage/storage.service';
import { User } from 'src/app/login/user';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  labels: any;
  language: string;
  user: User;

  constructor(
    private _router: Router,
    private navSideBarService: NavSideBarService,
    private storageService: StorageService,
  ) {
    this.labels = {
      english: {
        title: 'Users Form',
        commingSoon: 'Coming soon...'
      },
      spanish: {
        title: 'Formulario de Usuarios',
        commingSoon: 'Próximamente...'
      }
    }
  }

  ngOnInit() {
    this.storageService.language$.subscribe(language => this.language = language);
    this.user = this.storageService.getItem('session', 'user');
    this.navSideBarService.setCurrentRoute(this._router.url, this.language);
  }
}
