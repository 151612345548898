import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { sharedUrls } from './../../../shared/base-url';
import { environment } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserEventsService {
  apiUrl: string;
  imagesUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    const baseUrl = environment.baseUrl;
    this.apiUrl = `${baseUrl}${sharedUrls.apiUrl}`;
    this.imagesUrl = environment.imagesUrl;
  }

  getEventTypes(filters) {
    const url = `${this.apiUrl}/event-types`;
    const json = JSON.stringify(filters);
    const params = new HttpParams().set('json', json);

    return this.httpClient.get(url, { params: params });
  }

  getEventsList(filters) {
    const url = `${this.apiUrl}/events-list`;
    const json = JSON.stringify(filters);
    const params = new HttpParams().set('json', json);

    return this.httpClient.get(url, { params: params });
  }

  getMembersList(filters) {
    const url = `${this.apiUrl}/event-members-list`;
    const json = JSON.stringify(filters);
    const params = new HttpParams().set('json', json);

    return this.httpClient.get(url, { params: params });
  }

  memberSignUp(filters) {
    const url = `${this.apiUrl}/event-user-sign`;
    const json = JSON.stringify(filters);
    const params = new HttpParams().set('json', json);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.httpClient.post(url, params, { headers: headers });
  }

  memberUnsig(filters) {
    const url = `${this.apiUrl}/event-user-unsign`;
    const json = JSON.stringify(filters);
    const params = new HttpParams().set('json', json);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.httpClient.put(url, params, { headers: headers });
  }

  memberReferences(filters) {
    const url = `${this.apiUrl}/event-user-references`;
    let json = JSON.stringify(filters);
    let params = new HttpParams().set('json', json);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.httpClient.put(url, params, { headers: headers });
  }

  memberCheck(filters) {
    const url = `${this.apiUrl}/event-user-check`;
    let json = JSON.stringify(filters);
    let params = new HttpParams().set('json', json);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.httpClient.put(url, params, { headers: headers });
  }

  fileRequest(files: Array<File>) {
    const url = `${this.apiUrl}/upload-images`;
    return new Promise((resolve, reject) => {
      var formData: any = new FormData();
      var xhr = new XMLHttpRequest();

      for (let i = 0; i < files.length; i++) {
        formData.append('image[]', files[i], files[i].name);
      }
      formData.append('route', 'events');

      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      }

      xhr.open("POST", url, true);
      xhr.send(formData);
    });
  }

  saveAgreement(filters) {
    const url = `${this.apiUrl}/event-save-agreement`;
    const json = JSON.stringify(filters);
    const params = new HttpParams().set('json', json);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.httpClient.post(url, params, { headers: headers });
  }
}
