import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { sharedUrls } from './../../../shared/base-url';
import { environment } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserMembersService {
  apiUrl: string;
  imagesUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    const baseUrl = environment.baseUrl;
    this.apiUrl = `${baseUrl}${sharedUrls.apiUrl}`;
    this.imagesUrl = environment.imagesUrl;
  }

  getAssociationsList() {
    const url = `${this.apiUrl}/associations-list`;
    return this.httpClient.get(url);
  }

  getMemberTypes() {
    const url = `${this.apiUrl}/member-types`;
    return this.httpClient.get(url);
  }

  getMembersList(filters) {
    const url = `${this.apiUrl}/members-list`;
    let json = JSON.stringify(filters);
    let params = new HttpParams().set('json', json);

    return this.httpClient.get(url, { params: params });
  }

  getCollaboratorsList(filters) {
    const url = `${this.apiUrl}/collaborators-list`;
    let json = JSON.stringify(filters);
    let params = new HttpParams().set('json', json);

    return this.httpClient.get(url, { params: params });
  }
}
