import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class StorageService {
  configKey: string = 'config';
  configValues: any = null;
  isEnglishLanguage: boolean = false;
  currentLanguage: string;
  private language: BehaviorSubject<string> = new BehaviorSubject<string>(this.currentLanguage);
  language$: Observable<string> = this.language.asObservable();

  constructor(
    public localStorage: LocalStorageService, 
    public sessionStorage: SessionStorageService
  ) {
    this.configValues = {
      language: 'spanish',
      now: +new Date
    }
  }

  /**
   * Creates a new Local Storage var for system configurations.
   * @param {object} value
   * @public
   */
  setConfig(value) {
    this.localStorage.set(this.configKey, value, 0, 's');
    this.getConfig();
  }

  /**
   * Get the Local/Session Storage info.
   */
  getConfig() {
    if (!this.localStorage.get(this.configKey)) {
      this.localStorage.set(this.configKey, this.configValues, 0, 's');
    }

    const config = this.localStorage.get(this.configKey) || this.configKey;
    this.currentLanguage = config.language;
    this.language.next(this.currentLanguage);
  }

  changeLanguage() {
    this.isEnglishLanguage = !this.isEnglishLanguage;
    this.configValues.language = this.isEnglishLanguage ? 'english' : 'spanish'
    this.setConfig(this.configValues);
  }

  /**
   * Creates a new Local/Session Storage with an expiration time.
   * @param {string} type
   * @param {string} key
   * @param {any} value
   * @param {number} expirationTime
   * @param {string} expirationUnit
   */
  setItem(type, key, value, expirationTime = 0, expirationUnit: string = 's') {
    type = type.toLowerCase();
    key = key.toLowerCase();
    let unitEquivalence = 0;

    switch (expirationUnit) {
      case 'm':
        unitEquivalence = 60;
        break;
      case 'h':
        unitEquivalence = 60 * 60;
        break;
      case 'd':
        unitEquivalence = 60 * 60 * 24
        break;
      case 'm':
        unitEquivalence = 60 * 60 * 24 * 30
        break;
      case 'y':
        unitEquivalence = 60 * 60 * 24 * 365
        break;
      default:
        unitEquivalence = 1;
        break;
    }

    const timeExpired = expirationTime * unitEquivalence;
    if (type === 'local') {
      this.localStorage.set(key, value, timeExpired, 's');
    } else {
      this.sessionStorage.set(key, value, timeExpired, 's');
    }
  }

  /**
   * Get the Local/Session Storage info.
   * @public
   */
  getItem(type, key) {
    type = type.toLowerCase();
    key = key.toLowerCase();

    if (type === 'local' && !this.localStorage.get(key) || type === 'session' && !this.sessionStorage.get(key)) {
      return;
    }

    return type === 'local' ? this.localStorage.get(key) : this.sessionStorage.get(key);
  }

  /**
   * Removes the Local Storage var.
   * @public
   */
  removeItem(type, key) {
    type = type.toLowerCase();
    key = key.toLowerCase();

    if (type === 'local') {
      this.localStorage.remove(key);
      return
    }

    this.sessionStorage.remove(key);
  }

  /**
   * Clears Local/Session Storage info.
   * @public
   */
  clearStorage(type) {
    type = type.toLowerCase();

    if (type === 'local') {
      this.localStorage.clear();
      return
    }

    this.sessionStorage.clear();
  }
}
