import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StorageService } from './../storage/storage.service'

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  deviceInfo: any;
  labels: object;
  language: string;

  constructor(
    private deviceService: DeviceDetectorService,
    private storageService: StorageService
  ) {
    this.labels = {
      english: {
        title: 'Oops!',
        description: `The page you were looking for doesn't exist!`,
        suggestion: 'Please, click on Go Back button or select an option from the top menu.',
        goBack: 'Go Back'
      },
      spanish: {
        title: 'Oops!',
        description: `¡La página que estaba buscando no existe!`,
        suggestion: 'Por favor, haga clic en el botón Retroceder o seleccione una opción del menú superior.',
        goBack: 'Retroceder'
      }
    }
  }

  ngOnInit() {
    this.storageService.getConfig();
    this.storageService.language$.subscribe(language => this.language = language);
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  goBack() {
    window.history.back();
  }

}
