import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/login/user';
import { Router, ActivatedRoute } from '@angular/router';
import { NavSideBarService } from 'src/app/nav-side-bar/nav-side-bar.service';
import { StorageService } from 'src/app/storage/storage.service';
import { UserMembersService } from 'src/app/users/members/user-members/user-members.service';
import { Member } from 'src/app/users/members/user-members/member';
import { GeneralService } from 'src/app/services/general.service';
import { PropertiesService } from 'src/app/properties/properties.service';
import { RequestsService } from 'src/app/requests/requests.service';
import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.scss']
})
export class RequestFormComponent implements OnInit {
  labels: any;
  language: string;
  user: User;
  loading: boolean = true;
  saving: boolean = false;
  response: any;
  responseUpload: any;
  responseImages: any;
  collaboratorsList: Array<Member>;
  memberName: string;
  collaboratorId: number;
  requestId: number;
  requestTitle: string;
  requestAddress: string;
  requestBudget: number;
  requestCurrency: number;
  requestTypeId: number;
  requestUseId: number;
  requestIsRequest: boolean;
  requestIsActive: boolean;
  requestDescription: string;
  filesErrorMessage: string;
  photos: Array<string>;
  requestBedrooms: number;
  requestBathrooms: number;
  requestBuiltArea: number;
  requestLotSize: number;
  requestGarages: number;
  requestGym: number;
  requestPool: number;
  requestGate: number;
  modalData: any = {
    title: '',
    description: ''
  };
  currencyTypes: Array<any>;
  files: Array<any>;
  requestTypeList: Array<any>;
  requestUseList: Array<any>;

  constructor(
    private _router: Router,
    private navSideBarService: NavSideBarService,
    private storageService: StorageService,
    private userMembersService: UserMembersService,
    private generalService: GeneralService,
    private propertiesService: PropertiesService,
    private requestsService: RequestsService,
    private route: ActivatedRoute
  ) {
    this.labels = {
      english: {
        title: 'Requests Form',
        commingSoon: 'Coming soon...',
        loading: 'Loading',
        assessor: 'Assessor',
        select: 'Choose',
        publishedBy: 'Published By',
        requestTitle: 'What is your requirement?',
        requestAddress: 'Preferred Location',
        requestBudget: 'Budget',
        requestType: 'Property Type',
        requestUse: 'Land Use',
        requestSpecialFeature: 'Special Feature',
        placeholderTitle: 'Publishing Title',
        placeholderLocation: 'Property Location or Desirable Building',
        placeholderBudget: 'Max Budget',
        placeholderType: 'Select Property Type',
        placeholderLandUse: 'Select a Land Use',
        placeholderSpecialFeature: 'Beach, Seaview, Pets, Furnished, etc.',
        dragNDropLabelDesktop: 'Drag-and-drop here the images to upload. Only PNG or JPG files are allowed.',
        dragNDropLabelMobile: 'Click here and select the images to upload. Only PNG or JPG files are allowed.',
        imagesAllInvalid: 'No file matches with an allowed image file type.',
        imagesSomeInvalid: `Some files didn't match with an allowed image file type.`,
        warehouse: 'Warehouse',
        house: 'House',
        apartment: 'Apartment',
        study: 'Study',
        building: 'Building',
        hotel: 'Hotel',
        shop: 'Shop',
        office: 'Office',
        pentgarden: 'Pent Garden',
        penthouse: 'Pent House',
        lot: 'Lot',
        lot_urbanized: 'Lot Urbanized',
        requestDetails: 'Minimum Desirable Specifications',
        bedrooms: 'Bedrooms',
        bathrooms: 'Bathrooms',
        builtArea: 'Built Area',
        lotSize: 'Lot Size',
        garages: 'Parking',
        gym: 'Gym',
        pool: 'Pool',
        gate: 'Surveillance',
        placeholderBedrooms: 'Total Bedrooms',
        placeholderBathrooms: 'Total Bathrooms',
        placeholderGarages: 'Total Parkings',
        yes: 'Yes',
        no: 'No',
        save: 'Save',
        cancel: 'Cancel',
        successTitle: 'Congratulations',
        saveMessage: 'The request has been stored successfully with the Code XXX!',
        updateMessage: 'The request has been updated successfully',
        deleteMessage: 'The request has been deleted successfully!',
        confirmTitle: 'Warning',
        confirmMessage: 'Do you confirm you want to delete this request?',
        viewProperty: 'Click here to view the request',
        errorTitle: 'Oops...',
        errorMessage: 'The request was not saved. Please try again.',
        processingTitle: 'Processing Data',
        processingDescription: 'We are processing the data. Please, wait for a moment.',
        requestsListUrl: 'requests-list',
        delete: 'Delete',
        titleOptions: [
          'Looking for Rent',
          'Looking for Buy',
          'Looking for Investment',
          'Looking for Transfer',
          'Looking for Contribution',
          'Looking for Opportunity'
        ]
      },
      spanish: {
        title: 'Formulario de Solicitudes',
        loading: 'Cargado',
        assessor: 'Asesor',
        select: 'Selecciona',
        generalInfo: 'Datos Generales',
        publishedBy: 'Publicado por',
        requestTitle: '¿Cuál es tu requerimiento?',
        requestAddress: 'Ubicación Preferente',
        requestBudget: 'Presupuesto',
        requestType: 'Tipo de Inmueble',
        requestUse: 'Uso de Suelo',
        requestSpecialFeature: 'Característica Especial',
        placeholderTitle: 'Título de la Publicación',
        placeholderLocation: 'Desarrollo o edificio deseado',
        placeholderBudget: 'Presupuesto Máximo',
        placeholderType: 'Seleccione tipo de Inmueble',
        placeholderLandUse: 'Seleccione un uso de suelo',
        placeholderSpecialFeature: 'Playa, vista al mar, Mascotas, Amueblado, etc.',
        dragNDropLabelDesktop: 'Arrastre y suelte aquí las imágenes a cargar. Sólo se admiten archivos PNG o JPG.',
        dragNDropLabelMobile: 'Haga clic aquí y seleccione las imágenes a cargar. Sólo se admiten archivos PNG o JPG.',
        imagesAllInvalid: 'Ningún archivo corresponde a una imagen válida.',
        imagesSomeInvalid: 'Algunos archivos no corresponden a tipos de imágenes válidas.',
        warehouse: 'Bodega',
        house: 'Casa',
        apartment: 'Departamento',
        study: 'Estudio',
        building: 'Edificio',
        hotel: 'Hotel',
        shop: 'Local',
        office: 'Oficina',
        pentgarden: 'Pent Garden',
        penthouse: 'Pent House',
        lot: 'Terreno no urbanizado',
        lot_urbanized: 'Terreno urbanizado',
        requestDetails: 'Especificaciones Mínimas Deseables',
        bedrooms: 'Recámaras',
        bathrooms: 'Baños',
        builtArea: 'Construcción',
        lotSize: 'Terreno',
        garages: 'Garages',
        gym: 'Gimnasio',
        pool: 'Alberca',
        gate: 'Vigilancia',
        placeholderBedrooms: 'Total de Recámaras',
        placeholderBathrooms: 'Total de Baños',
        placeholderGarages: 'Total de Garages',
        yes: 'Si',
        no: 'No',
        save: 'Guardar',
        cancel: 'Cancelar',
        successTitle: 'Felicidades',
        saveMessage: '¡La solicitud ha sido registrada exitosamente con la referencia XXX!',
        updateMessage: '¡La solicitud ha sido actualizada exitosamente!',
        deleteMessage: '¡La solicitud ha sido eliminada exitosamente!',
        confirmTitle: 'Aviso',
        confirmMessage: '¿Realmente deseas eliminar esta solicitud?',
        viewProperty: 'Haga clic aquí para ver la solicitud',
        errorTitle: 'Oops...',
        errorMessage: 'La solicitud no fue guardada. Por favor, intente nuevamente.',
        processingTitle: 'Procesando datos',
        processingDescription: 'Se está procesando la información. Por favor, espere un momento.',
        requestsListUrl: 'listado-solicitudes',
        delete: 'Borrar',
        titleOptions: [
          'Busco para Renta',
          'Busco para Venta',
          'Busco para Inversión',
          'Busco para Traspaso',
          'Busco para Aportación',
          'Busco de Oportunidad'
        ]
      }
    }
  }

  get invalidForm() {
    return !this.requestTitle.length 
      || this.requestTypeId === 0
      || this.requestUseId === 0;
  }

  ngOnInit() {
    $('#dialogBox').on('hidden.bs.modal', (e) => {
      this.closeModal();
    });

    this.requestId = Number.parseInt(this.route.snapshot.paramMap.get("requestId"));
    this.storageService.language$.subscribe(language => this.language = language);
    this.user = this.storageService.getItem('session', 'user');
    this.memberName = this.generalService.capitalizeAndTrim(this.user.user_name);
    this.navSideBarService.setCurrentRoute(this._router.url, this.language);
    this.loading = true;
    this.resetForm();
    this.requestTypeList = [];
    this.requestUseList = [];
    this.currencyTypes = [
      {
        id: 1,
        value: 'MXN'
      },
      {
        id: 2,
        value: 'USD'
      }
    ];
    this.requestIsRequest = false;
    this.requestIsActive = true;

    const filters = {
      memberId: this.user.member_id
    };

    if (this.requestId) {
      this.requestsService.getRequest(this.requestId).subscribe(response => {
        this.response = response;

        if (this.response.code === 200) {
          if (this.response.record_found) {
            const request = this.response.data.request[0];
            const editable = this.user.user_type === 'Admin' && ['Super', 'Admin'].includes(this.user.user_type_group) || 
              (this.user.user_type === 'Admin' && this.user.user_type_group === 'Association' && this.user.association_id == request.association_id) || 
              (this.user.user_type !== 'Admin' && this.user.member_id == request.member_id);

              if (!editable) {
              const requestsListUrl = `/${this.labels[this.language].requestsListUrl}`;
              this._router.navigate([requestsListUrl])
            }

            this.requestTypeId = request.property_type_id
            this.requestTitle = request.name
            this.requestAddress = request.address;
            this.requestDescription = request.description;
            this.requestBudget = request.price
            this.requestCurrency = request.currency_id;
            this.user.user_id = request.created_by;
            this.user.member_id = request.member_id;
            this.collaboratorId = request.contact_id;
            this.requestUseId = request.property_use_id;
            this.requestIsRequest = false;
            this.requestIsActive = request.is_active;
            this.requestBuiltArea = request.attributes.built_area || null;
            this.requestLotSize = request.attributes.lot_size || null;
            this.requestBedrooms = request.attributes.beds || null;
            this.requestBathrooms = request.attributes.baths || null;
            this.requestGarages = request.attributes.garages || null;
            this.requestGym = request.attributes.gym || 0;
            this.requestPool = request.attributes.pool || 0;
            this.requestGate = request.attributes.gate || 0;
          }
        }
      });
    }

    this.propertiesService.getPropertiesTypes().subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        if (this.response.totalRecords > 0) {
          this.requestTypeList = this.response.propertiesTypes.map(item => {
            const type = {
              key: item.id,
              value: this.labels[this.language][item.name]
            };
            return type;
          });
        }
      }
    });

    this.propertiesService.getPropertiesUses().subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        if (this.response.totalRecords > 0) {
          this.requestUseList = this.response.propertiesUses.map(item => {
            const use = {
              key: item.id,
              value: item.name
            };
            return use;
          });
        }
      }
    });

    this.userMembersService.getCollaboratorsList(filters).subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        this.loading = false;

        if (this.response.totalRecords > 0) {
          this.collaboratorsList = this.response.data.map(collaborator => {
            collaborator.agent_full_name = this.generalService.capitalizeAndTrim(collaborator.agent_full_name);
            return collaborator;
          });
        }
      }
    });
  }

  resetForm() {
    this.requestTitle = this.labels[this.language].titleOptions[0];
    this.requestAddress = '';
    this.requestDescription = '';
    this.requestBudget = null;
    this.requestCurrency = 1;
    this.collaboratorId = 0;
    this.requestTypeId = 0;
    this.requestUseId = 0;
    this.requestBuiltArea = null;
    this.requestLotSize = null;
    this.requestBedrooms = null;
    this.requestBathrooms = null;
    this.requestGarages = null;
    this.requestGym = 0;
    this.requestPool = 0;
    this.requestGate = 0;
    this.files = [];
    this.filesErrorMessage = '';
  }

  saveForm() {
    const params = {
      requestId: this.requestId || null,
      typeId: this.requestTypeId,
      name: this.requestTitle,
      address: this.requestAddress,
      description: this.requestDescription,
      price: this.requestBudget,
      currencyId: this.requestCurrency,
      createdBy: this.user.user_id,
      memberId: this.user.member_id,
      contactId: this.collaboratorId,
      isOffer: 0,
      requestUseId: this.requestUseId,
      isActive: 1,
      attributes: {
        builtArea: this.requestBuiltArea,
        lotSize: this.requestLotSize,
        bedrooms: this.requestBedrooms,
        bathrooms: this.requestBathrooms,
        garages: this.requestGarages,
        gym: this.requestGym,
        pool: this.requestPool,
        gate: this.requestGate
      }
    };

    this.modalData = {}
    $('#dialogBox').modal('show');
    this.requestsService.saveRequest(this.requestId, params).subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        if (this.response.status === 'success') {
          const requestId = this.response.property_id;
          const message = !this.requestId
            ? this.labels[this.language].saveMessage.replace('XXX', requestId)
            : this.labels[this.language].updateMessage
          this.modalData = {
            title: this.labels[this.language].successTitle,
            message: message,
            viewProperty: this.labels[this.language].viewProperty
          };
          if (!this.requestId) {
            this.resetForm();
          }
        } else {
          this.modalData = {
            title: this.labels[this.language].errorTitle,
            message: this.labels[this.language].errorMessage
          };
          console.error(this.response.complement);
        }
      } else {
        this.modalData = {
          title: this.labels[this.language].errorTitle,
          message: this.labels[this.language].errorMessage
        };
        console.error(response);
      }
    }, error => {
      this.modalData = {
        title: this.labels[this.language].errorTitle,
        message: this.labels[this.language].errorMessage
      };
      console.error(<any>error);
    });
  }

  closeModal() {
    if (!['confirm', 'error'].includes(this.modalData.type)) {
      this.exit();
    }
  }

  exit() {
    const requestsListUrl = `/${this.labels[this.language].requestsListUrl}`;
    this._router.navigate([requestsListUrl])
  }

  confirmDelete() {
    this.modalData = {
      title: this.labels[this.language].confirmTitle,
      message: this.labels[this.language].confirmMessage,
      type: 'confirm'
    };
    $('#dialogBox').modal('show');
  }

  deleteRequest() {
    if (!this.requestId) {
      return
    }

    this.modalData = {}
    $('#dialogBox').modal('show');
    this.requestsService.deleteRequest(this.requestId).subscribe(response => {
      this.response = response;

      if (this.response.code === 200) {
        this.modalData = {
          title: this.labels[this.language].successTitle,
          message: this.labels[this.language].deleteMessage,
          type: 'info'
        };
      }
    });
  }
}
