import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MetaGuard } from '@ngx-meta/core';
import { ErrorComponent } from './error/error.component';
import { PropertiesComponent } from './properties/properties.component';
import { PropertyComponent } from './properties/property/property.component';
import { RequestComponent } from './requests/request/request.component';
import { RequestsComponent } from './requests/requests.component';
import { EventsComponent } from './events/events.component';
import { UserDashbordComponent } from './users/dashboard/user-dashbord/user-dashbord.component';
import { CatalogComponent } from './users/catalog/catalog.component'
import { UserFormComponent } from './users/catalog/user-form/user-form.component'
import { UserMembersComponent } from './users/members/user-members/user-members.component';
import { MemberFormComponent } from './users/members/user-members/member-form/member-form.component';
import { UserProfileComponent } from './users/profile/user-profile/user-profile.component';
import { UserPropertiesComponent } from './users/properties/user-properties/user-properties.component';
import { PropertyFormComponent } from './users/properties/user-properties/property-form/property-form.component';
import { UserRequestsComponent } from './users/requests/user-requests/user-requests.component';
import { RequestFormComponent } from './users/requests/user-requests/request-form/request-form.component';
import { UserEventsComponent } from './users/events/user-events/user-events.component';
import { EventFormComponent } from './users/events/user-events/event-form/event-form.component';
import { LoginGuardService } from './login/login-guard.service';

const routes: Routes = [
  { path: '', component: PropertiesComponent },
  { path: 'propiedades', component: PropertiesComponent },
  { path: 'properties', component: PropertiesComponent },
  { path: 'propiedad/:propertyId', component: PropertyComponent, canActivateChild: [MetaGuard] },
  { path: 'property/:propertyId', component: PropertyComponent, canActivateChild: [MetaGuard] },
  { path: 'propiedad/:propertyId/agente/:agentId', component: PropertyComponent },
  { path: 'propiedad/:propertyId/agent/:agentId', component: PropertyComponent },
  { path: 'propiedad/:propertyId/socio/:agentId', component: PropertyComponent },
  { path: 'propiedad/:propertyId/member/:agentId', component: PropertyComponent },
  { path: 'solicitudes', component: RequestsComponent },
  { path: 'requests', component: RequestsComponent },
  { path: 'solicitud/:requestId', component: RequestComponent, canActivateChild: [MetaGuard] },
  { path: 'request/:requestId', component: RequestComponent, canActivateChild: [MetaGuard] },
  { path: 'eventos', component: EventsComponent },
  { path: 'events', component: EventsComponent },
  { path: 'inicio', component: UserDashbordComponent, canActivate: [LoginGuardService]},
  { path: 'home', component: UserDashbordComponent, canActivate: [LoginGuardService]},
  { path: 'perfil-usuario', component: UserProfileComponent, canActivate: [LoginGuardService]},
  { path: 'user-profile', component: UserProfileComponent, canActivate: [LoginGuardService]},
  { path: 'nuevo-usuario', component: UserFormComponent, canActivate: [LoginGuardService]},
  { path: 'new-user', component: UserFormComponent, canActivate: [LoginGuardService]},
  { path: 'listado-usuarios', component: CatalogComponent, canActivate: [LoginGuardService]},
  { path: 'users-list', component: CatalogComponent, canActivate: [LoginGuardService]},
  { path: 'nuevo-socio', component: MemberFormComponent, canActivate: [LoginGuardService]},
  { path: 'new-member', component: MemberFormComponent, canActivate: [LoginGuardService]},
  { path: 'listado-socios', component: UserMembersComponent, canActivate: [LoginGuardService]},
  { path: 'members-list', component: UserMembersComponent, canActivate: [LoginGuardService]},
  { path: 'nueva-propiedad', component: PropertyFormComponent, canActivate: [LoginGuardService]},
  { path: 'new-property', component: PropertyFormComponent, canActivate: [LoginGuardService]},
  { path: 'editar-propiedad/:propertyId', component: PropertyFormComponent, canActivate: [LoginGuardService]},
  { path: 'edit-property/:propertyId', component: PropertyFormComponent, canActivate: [LoginGuardService]},
  { path: 'listado-propiedades', component: UserPropertiesComponent, canActivate: [LoginGuardService]},
  { path: 'properties-list', component: UserPropertiesComponent, canActivate: [LoginGuardService]},
  { path: 'nueva-solicitud', component: RequestFormComponent, canActivate: [LoginGuardService]},
  { path: 'new-request', component: RequestFormComponent, canActivate: [LoginGuardService]},
  { path: 'editar-solicitud/:requestId', component: RequestFormComponent, canActivate: [LoginGuardService]},
  { path: 'edit-request/:requestId', component: RequestFormComponent, canActivate: [LoginGuardService]},
  { path: 'listado-solicitudes', component: UserRequestsComponent, canActivate: [LoginGuardService]},
  { path: 'requests-list', component: UserRequestsComponent, canActivate: [LoginGuardService]},
  { path: 'nuevo-evento', component: EventFormComponent, canActivate: [LoginGuardService]},
  { path: 'new-event', component: EventFormComponent, canActivate: [LoginGuardService]},
  { path: 'listado-eventos', component: UserEventsComponent, canActivate: [LoginGuardService]},
  { path: 'events-list', component: UserEventsComponent, canActivate: [LoginGuardService]},
  { path: '**', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
