import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/login/user';
import { Router, ActivatedRoute } from '@angular/router';
import { NavSideBarService } from 'src/app/nav-side-bar/nav-side-bar.service';
import { StorageService } from 'src/app/storage/storage.service';
import { UserMembersService } from 'src/app/users/members/user-members/user-members.service';
import { Member } from 'src/app/users/members/user-members/member';
import { GeneralService } from 'src/app/services/general.service';
import { PropertiesService } from 'src/app/properties/properties.service';
import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-property-form',
  templateUrl: './property-form.component.html',
  styleUrls: ['./property-form.component.scss']
})
export class PropertyFormComponent implements OnInit {
  labels: any;
  language: string;
  user: User;
  loading: boolean = true;
  saving: boolean = false;
  response: any;
  responseUpload: any;
  responseImages: any;
  collaboratorsList: Array<Member>;
  memberName: string;
  collaboratorId: number;
  propertyId: number;
  propertyTitle: string;
  propertyDescription: string;
  propertyUrl: string;
  propertyPrice: number;
  propertyCurrency: number;
  propertyTypeId: number;
  propertyUseId: number;
  propertyIsRequest: boolean;
  propertyIsActive: boolean;
  propertyLandRegistry: string;
  propertyIsOwn: boolean;
  propertyKeywords: string;
  propertyDefaultPhoto: string;
  filesErrorMessage: string;
  photos: Array<string>;
  propertyBedrooms: number;
  propertyBathrooms: number;
  propertyBuiltArea: number;
  propertyLotSize: number;
  propertyGarages: number;
  propertyGym: number;
  propertyPool: number;
  propertyGate: number;
  modalData: any = {
    title: '',
    description: ''
  };
  currencyTypes: Array<any>;
  files: Array<any>;
  propertyTypeList: Array<any>;
  propertyUseList: Array<any>;
  photosURL: string = 'https://ampicun.org/img/';
  private static EXTERNAL_KEYWORD = '#ext#';

  constructor(
    private _router: Router,
    private navSideBarService: NavSideBarService,
    private storageService: StorageService,
    private userMembersService: UserMembersService,
    private generalService: GeneralService,
    private propertiesService: PropertiesService,
    private route: ActivatedRoute
  ) {
    this.labels = {
      english: {
        title: 'Properties Form',
        commingSoon: 'Coming soon...',
        loading: 'Loading',
        assessor: 'Assessor',
        publishedBy: 'Published By',
        propertyTitle: 'Title',
        propertyDescription: 'Description',
        propertyPhoto: 'Photo',
        propertyUrl: 'URL',
        propertyPrice: 'Price',
        propertyType: 'Property Type',
        propertyUse: 'Land Use',
        propertyLandRegistry: 'Land Registry',
        propertyOwnership: 'Ownership',
        propertyOwnershipTrue: 'Own Property',
        propertyOwnershipFalse: 'Third-party Property',
        propertyKeywords: 'Keywords',
        placeholderTitle: 'Publishing Title',
        placeholderDescription: 'Property Description',
        placeholderUrl: 'i.e. www.domain.com/property-link',
        placeholderPrice: 'Property Price',
        placeholderType: 'Select a Type',
        placeholderLandUse: 'Select a Land Use',
        placeholderLandRegistry: 'This field is optional',
        placeholderKeywords: 'Please use commas to separate each keyword',
        dragNDropLabelDesktop: 'Drag-and-drop here the images to upload. Only PNG or JPG files are allowed.',
        dragNDropLabelMobile: 'Click here and select the images to upload. Only PNG or JPG files are allowed.',
        imagesAllInvalid: 'No file matches with an allowed image file type.',
        imagesSomeInvalid: `Some files didn't match with an allowed image file type.`,
        warehouse: 'Warehouse',
        house: 'House',
        apartment: 'Apartment',
        study: 'Study',
        building: 'Building',
        hotel: 'Hotel',
        shop: 'Shop',
        office: 'Office',
        pentgarden: 'Pent Garden',
        penthouse: 'Pent House',
        lot: 'Lot',
        lot_urbanized: 'Lot Urbanized',
        propertyDetails: 'Property Details',
        bedrooms: 'Bedrooms',
        bathrooms: 'Bathrooms',
        builtArea: 'Built Area',
        lotSize: 'Lot Size',
        garages: 'Parking',
        gym: 'Gym',
        pool: 'Pool',
        gate: 'Gate',
        placeholderBedrooms: 'Total Bedrooms',
        placeholderBathrooms: 'Total Bathrooms',
        placeholderGarages: 'Total Parkings',
        yes: 'Yes',
        no: 'No',
        save: 'Save',
        cancel: 'Cancel',
        successTitle: 'Congratulations',
        saveMessage: 'The property has been stored successfully with the Code XXX!',
        updateMessage: 'The property has been updated successfully',
        deleteMessage: 'The property has been deleted successfully!',
        confirmTitle: 'Warning',
        confirmMessage: 'Do you confirm you want to delete this property?',
        viewProperty: 'Click here to view the property',
        errorTitle: 'Oops...',
        errorSaveMessage: 'The property was not saved. Please try again.',
        errorImagesMessage: 'However, we could not upload the images.',
        errorImagesDBMessage: 'However, we could save the images into Database.',
        processingTitle: 'Processing Data',
        processingDescription: 'We are processing the data. Please, wait for a moment.',
        propertiesListUrl: 'properties-list',
        delete: 'Delete'
      },
      spanish: {
        title: 'Formulario de Propiedades',
        commingSoon: 'Próximamente...',
        loading: 'Cargado',
        assessor: 'Asesor',
        generalInfo: 'Datos Generales',
        publishedBy: 'Publicado por',
        propertyTitle: 'Título',
        propertyDescription: 'Descripción',
        propertyPhoto: 'Foto',
        propertyUrl: 'URL',
        propertyPrice: 'Precio',
        propertyType: 'Tipo',
        propertyUse: 'Uso de Suelo',
        propertyLandRegistry: 'Céd. Catastral',
        propertyOwnership: 'Pertenencia',
        propertyOwnershipTrue: 'Propia',
        propertyOwnershipFalse: 'Propiedad de un Tercero',
        propertyKeywords: 'Keywords',
        placeholderTitle: 'Título de la Publicación',
        placeholderDescription: 'Descripción de la propiedad',
        placeholderUrl: 'i.e. www.dominio.com/link-de-la-propiedad',
        placeholderPrice: 'Precio de la propiedad',
        placeholderType: 'Seleccione un tipo de Propiedad',
        placeholderLandUse: 'Seleccione un uso de suelo',
        placeholderLandRegistry: 'Este campo es opcional',
        placeholderKeywords: 'Por favor, use comas para separar cada keyword',
        dragNDropLabelDesktop: 'Arrastre y suelte aquí las imágenes a cargar. Sólo se admiten archivos PNG o JPG.',
        dragNDropLabelMobile: 'Haga clic aquí y seleccione las imágenes a cargar. Sólo se admiten archivos PNG o JPG.',
        imagesAllInvalid: 'Ningún archivo corresponde a una imagen válida.',
        imagesSomeInvalid: 'Algunos archivos no corresponden a tipos de imágenes válidas.',
        warehouse: 'Bodega',
        house: 'Casa',
        apartment: 'Departamento',
        study: 'Estudio',
        building: 'Edificio',
        hotel: 'Hotel',
        shop: 'Local',
        office: 'Oficina',
        pentgarden: 'Pent Garden',
        penthouse: 'Pent House',
        lot: 'Terreno no urbanizado',
        lot_urbanized: 'Terreno urbanizado',
        propertyDetails: 'Especificaciones',
        bedrooms: 'Recámaras',
        bathrooms: 'Baños',
        builtArea: 'Construcción',
        lotSize: 'Terreno',
        garages: 'Garages',
        gym: 'Gimnasio',
        pool: 'Alberca',
        gate: 'Caseta',
        placeholderBedrooms: 'Total de Recámaras',
        placeholderBathrooms: 'Total de Baños',
        placeholderGarages: 'Total de Garages',
        yes: 'Si',
        no: 'No',
        save: 'Guardar',
        cancel: 'Cancelar',
        successTitle: 'Felicidades',
        saveMessage: '¡La propiedad ha sido registrada exitosamente con la referencia XXX!',
        updateMessage: '¡La propiedad ha sido actualizada exitosamente!',
        deleteMessage: '¡La propiedad ha sido eliminada exitosamente!',
        confirmTitle: 'Aviso',
        confirmMessage: '¿Realmente deseas eliminar esta propiedad?',
        viewProperty: 'Haga clic aquí para ver la propiedad',
        errorTitle: 'Oops...',
        errorSaveMessage: 'La propiedad no fue guardada. Por favor, intente nuevamente.',
        errorImagesMessage: 'Sin embargo, no se pudieron cargar las imágenes.',
        errorImagesDBMessage: 'Sin embargo, no se pudieron grabar las imágenes en la base de datos.',
        processingTitle: 'Procesando datos',
        processingDescription: 'Se está procesando la información. Por favor, espere un momento.',
        propertiesListUrl: 'listado-propiedades',
        delete: 'Borrar'
      }
    }
  }

  get invalidForm() {
    return !this.propertyTitle.length 
      || !this.propertyDescription.length 
      || (!this.files.length && !this.photos.length)
      || !this.propertyUrl.length
      || (!this.propertyPrice || this.propertyPrice === 0)
      || this.propertyTypeId === 0
      || this.propertyUseId === 0;
  }

  ngOnInit() {
    $('#dialogBox').on('hidden.bs.modal', (e) => {
      this.closeModal();
    });

    this.propertyId = Number.parseInt(this.route.snapshot.paramMap.get("propertyId"));
    this.storageService.language$.subscribe(language => this.language = language);
    this.user = this.storageService.getItem('session', 'user');
    this.memberName = this.generalService.capitalizeAndTrim(this.user.user_name);
    this.navSideBarService.setCurrentRoute(this._router.url, this.language);
    this.loading = true;
    this.resetForm();
    this.propertyTypeList = [];
    this.propertyUseList = [];
    this.currencyTypes = [
      {
        id: 1,
        value: 'MXN'
      },
      {
        id: 2,
        value: 'USD'
      }
    ];
    this.propertyIsRequest = false;
    this.propertyIsActive = true;

    const filters = {
      memberId: this.user.member_id
    };

    if (this.propertyId) {
      this.propertiesService.getProperty(this.propertyId).subscribe(response => {
        this.response = response;

        if (this.response.code === 200) {
          if (this.response.record_found) {
            const property = this.response.data.property[0];
            const editable = this.user.user_type === 'Admin' && ['Super', 'Admin'].includes(this.user.user_type_group) || 
              (this.user.user_type === 'Admin' && this.user.user_type_group === 'Association' && this.user.association_id == property.association_id) || 
              (this.user.user_type !== 'Admin' && this.user.member_id == property.member_id);

              if (!editable) {
              const propertiesListUrl = `/${this.labels[this.language].propertiesListUrl}`;
              this._router.navigate([propertiesListUrl])
            }

            const externalKeywordIndex = !!property.keywords
              ? property.keywords
                .split(',')
                .findIndex(keyword => keyword.trim() === PropertyFormComponent.EXTERNAL_KEYWORD)
              : -1;
            this.propertyTypeId = property.property_type_id
            this.propertyTitle = property.name
            this.propertyDescription = property.description;
            this.propertyLandRegistry = property.land_registry;
            this.propertyIsOwn = externalKeywordIndex < 0;
            this.propertyKeywords = property.keywords;
            this.propertyDefaultPhoto = property.property_photo_path || property.photo_path;
            this.propertyUrl = property.property_url;
            this.propertyPrice = property.price
            this.propertyCurrency = property.currency_id;
            this.user.user_id = property.created_by;
            this.user.member_id = property.member_id;
            this.collaboratorId = property.contact_id;
            this.propertyUseId = property.property_use_id;
            this.propertyIsRequest = false;
            this.propertyIsActive = property.is_active;
            this.propertyBuiltArea = property.attributes.built_area || null;
            this.propertyLotSize = property.attributes.lot_size || null;
            this.propertyBedrooms = property.attributes.beds || null;
            this.propertyBathrooms = property.attributes.baths || null;
            this.propertyGarages = property.attributes.garages || null;
            this.propertyGym = property.attributes.gym || 0;
            this.propertyPool = property.attributes.pool || 0;
            this.propertyGate = property.attributes.gate || 0;

            this.photos = property.photos ? property.photos.split(',') : [];
            if (!this.photos.length) {
              this.photos.push(property.no_photo);
            }
          }
        }
      });
    }

    this.propertiesService.getPropertiesTypes().subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        if (this.response.totalRecords > 0) {
          this.propertyTypeList = this.response.propertiesTypes.map(item => {
            const type = {
              key: item.id,
              value: this.labels[this.language][item.name]
            };
            return type;
          });
        }
      }
    });

    this.propertiesService.getPropertiesUses().subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        if (this.response.totalRecords > 0) {
          this.propertyUseList = this.response.propertiesUses.map(item => {
            const use = {
              key: item.id,
              value: item.name
            };
            return use;
          });
        }
      }
    });

    this.userMembersService.getCollaboratorsList(filters).subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        this.loading = false;

        if (this.response.totalRecords > 0) {
          this.collaboratorsList = this.response.data.map(collaborator => {
            collaborator.agent_full_name = this.generalService.capitalizeAndTrim(collaborator.agent_full_name);
            return collaborator;
          });
        }
      }
    });
  }

  uploadFile(filesList) {
    for (let index = 0; index < filesList.length; index++) {
      const file = filesList[index];

      if (['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
        this.files.push(file)
      }
    }

    this.filesErrorMessage = '';
    if (filesList.length && !this.files.length) {
      this.filesErrorMessage = this.labels[this.language].imagesAllInvalid;
    }
    else if (filesList.length > this.files.length) {
      this.filesErrorMessage = this.labels[this.language].imagesSomeInvalid;
    }
  }

  deleteFile(index) {
    this.filesErrorMessage = '';
    this.files.splice(index, 1)
  }

  resetForm() {
    this.propertyTitle = '';
    this.propertyDescription = '';
    this.propertyLandRegistry = '';
    this.propertyIsOwn = true;
    this.propertyKeywords = '';
    this.propertyUrl = '';
    this.propertyPrice = null;
    this.propertyCurrency = 1;
    this.collaboratorId = 0;
    this.propertyTypeId = 0;
    this.propertyUseId = 0;
    this.propertyBuiltArea = null;
    this.propertyLotSize = null;
    this.propertyBedrooms = null;
    this.propertyBathrooms = null;
    this.propertyGarages = null;
    this.propertyGym = 0;
    this.propertyPool = 0;
    this.propertyGate = 0;
    this.files = [];
    this.filesErrorMessage = '';
  }

  onOwnershipChange(isOwnProperty) {
    if (isOwnProperty) {
      const newKeywords = !!this.propertyKeywords
        ? this.propertyKeywords
          .split(',')
          .filter(keyword => keyword.trim() !== PropertyFormComponent.EXTERNAL_KEYWORD)
          .map(keyword => keyword.trim())
          .join(', ')
        : null;
      this.propertyKeywords = newKeywords;
    } else {
      const newKeywords = !!this.propertyKeywords 
        ? this.propertyKeywords
          .split(',')
          .map(keyword => keyword.trim())
        : [];
      newKeywords.push(PropertyFormComponent.EXTERNAL_KEYWORD);
      this.propertyKeywords = newKeywords.join(', ');
    }
  }

  saveForm() {
    const params = {
      propertyId: this.propertyId || null,
      typeId: this.propertyTypeId,
      name: this.propertyTitle,
      description: this.propertyDescription,
      landRegistry: this.propertyLandRegistry,
      keywords: this.propertyKeywords,
      url: this.propertyUrl,
      price: this.propertyPrice,
      currencyId: this.propertyCurrency,
      createdBy: this.user.user_id,
      memberId: this.user.member_id,
      contactId: this.collaboratorId,
      isOffer: 1,
      propertyUseId: this.propertyUseId,
      isActive: 1,
      attributes: {
        builtArea: this.propertyBuiltArea,
        lotSize: this.propertyLotSize,
        bedrooms: this.propertyBedrooms,
        bathrooms: this.propertyBathrooms,
        garages: this.propertyGarages,
        gym: this.propertyGym,
        pool: this.propertyPool,
        gate: this.propertyGate
      },
      photos: this.files || this.photos
    };

    this.modalData = {}
    $('#dialogBox').modal('show');
    this.propertiesService.saveProperty(params).subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        if (this.response.status === 'success') {
          const propertyId = this.response.property_id;
          this.propertiesService.fileRequest(propertyId, this.files).then((result) => {
            this.responseUpload = result;
            if (this.responseUpload.code === 200) {
              if (this.responseUpload.status === 'success') {
                const uploadedImages = this.responseUpload.uploaded_images;
                const imagesParams = {
                  propertyId,
                  uploadedImages
                };
                this.propertiesService.savePropertyImages(imagesParams).subscribe(imagesResult => {
                  this.responseImages = imagesResult;
                  const message = !this.propertyId
                    ? this.labels[this.language].saveMessage.replace('XXX', propertyId)
                    : this.labels[this.language].updateMessage
                  this.modalData = {
                    title: this.labels[this.language].successTitle,
                    message: message,
                    viewProperty: this.labels[this.language].viewProperty,
                    url: `https://ficha.vip/ficha.php?id=${propertyId}&c=1`,
                    type: 'info'
                  };

                  if (!this.propertyId) {
                    this.resetForm();
                  }
                }, (error) => {
                  const saveMessage = !this.propertyId
                    ? this.labels[this.language].saveMessage.replace('XXX', propertyId)
                    : this.labels[this.language].updateMessage;
                  const message = `${saveMessage} ${this.labels[this.language].errorImagesDBMessage}`;
                  this.modalData = {
                    title: this.labels[this.language].successTitle,
                    message: message,
                    viewProperty: this.labels[this.language].viewProperty,
                    url: `https://ficha.vip/ficha.php?id=${propertyId}&c=1`,
                    type: 'info'
                  };
                  console.error(error);
                })
              } else {
                const saveMessage = !this.propertyId
                  ? this.labels[this.language].saveMessage.replace('XXX', propertyId)
                  : this.labels[this.language].updateMessage;
                const message = `${saveMessage} ${this.labels[this.language].errorImagesMessage}`;
                this.modalData = {
                  title: this.labels[this.language].successTitle,
                  message: message,
                  viewProperty: this.labels[this.language].viewProperty,
                  url: `https://ficha.vip/ficha.php?id=${propertyId}&c=1`,
                  type: 'info'
                };
                console.error(this.responseUpload.message);
              }
            } else {
              const saveMessage = !this.propertyId
                ? this.labels[this.language].saveMessage.replace('XXX', propertyId)
                : this.labels[this.language].updateMessage;
              const message = `${saveMessage} ${this.labels[this.language].errorImagesMessage}`;
              this.modalData = {
                title: this.labels[this.language].successTitle,
                message: message,
                viewProperty: this.labels[this.language].viewProperty,
                url: `https://ficha.vip/ficha.php?id=${propertyId}&c=1`,
                type: 'info'
              };
              console.error(this.responseUpload.message);
            }
          }, (error) => {
            const saveMessage = !this.propertyId
              ? this.labels[this.language].saveMessage.replace('XXX', propertyId)
              : this.labels[this.language].updateMessage;
            const message = `${saveMessage} ${this.labels[this.language].errorImagesMessage}`;
            this.modalData = {
              title: this.labels[this.language].successTitle,
              message: message,
              viewProperty: this.labels[this.language].viewProperty,
              url: `https://ficha.vip/ficha.php?id=${propertyId}&c=1`,
              type: 'info'
            };
            console.error(error);
            return;
          });
        } else {
          this.modalData = {
            title: this.labels[this.language].errorTitle,
            message: this.labels[this.language].errorSaveMessage,
            type: 'error'
          };
          console.error(this.response.complement);
        }
      } else {
        this.modalData = {
          title: this.labels[this.language].errorTitle,
          message: this.labels[this.language].errorSaveMessage,
          type: 'error'
        };
        console.error(response);
      }
    }, error => {
      this.modalData = {
        title: this.labels[this.language].errorTitle,
        message: this.labels[this.language].errorSaveMessage,
        type: 'error'
      };
      console.error(<any>error);
    });
  }

  closeModal() {
    if (!['confirm', 'error'].includes(this.modalData.type)) {
      this.exit();
    }
  }

  setAsDefault(propertyId, path) {
    if (!propertyId || !path) {
      return;
    }

    this.propertiesService.setPropertyImage(propertyId, path).subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        if (this.response.status === 'success') {
          this.propertyDefaultPhoto = path;
        }
      }
    })
  }

  deletePhoto(propertyId, path) {
    if (!propertyId || !path) {
      return;
    }

    this.propertiesService.deletePropertyImages(propertyId, path).subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        if (this.response.status === 'success') {
          this.photos = this.photos.filter(photo => photo !== path);
        }
      }
    })
  }

  exit() {
    const propertiesListUrl = `/${this.labels[this.language].propertiesListUrl}`;
    this._router.navigate([propertiesListUrl])
  }

  confirmDelete() {
    this.modalData = {
      title: this.labels[this.language].confirmTitle,
      message: this.labels[this.language].confirmMessage,
      type: 'confirm'
    };
    $('#dialogBox').modal('show');
  }

  deleteProperty() {
    if (!this.propertyId) {
      return
    }

    this.modalData = {}
    $('#dialogBox').modal('show');
    this.propertiesService.deleteProperty(this.propertyId).subscribe(response => {
      this.response = response;

      if (this.response.code === 200) {
        this.modalData = {
          title: this.labels[this.language].successTitle,
          message: this.labels[this.language].deleteMessage,
          type: 'info'
        };
      }
    });
  }
}