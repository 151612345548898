import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { sharedUrls } from './../shared/base-url';
import { Router } from '@angular/router';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  token: string;
  response: any;

  constructor(
    private _router: Router,
    private httpClient: HttpClient,
    private storageService: StorageService
  ) {
    this.token = null;
  }

  login(user) {
    this.storageService.removeItem('session', 'user');
    const baseUrl = environment.baseUrl;
    const url = `${baseUrl}${sharedUrls.apiUrl}/login`;
    let json = JSON.stringify(user);
    let params = new HttpParams().set('json', json);
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.httpClient.post(url, params, { headers: headers });
  }

  logout() {
    this.storageService.removeItem('session', 'user');
    window.location.href = '/';
  }

  isAuthenticated() {
    return Boolean(this.storageService.getItem('session', 'user'));
  }
}
