import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from './../storage/storage.service';
import { NavBarService } from './nav-bar.service';
import { LoginService } from './../login/login.service';
import { NgForm } from '@angular/forms';
import { User } from './../login/user';
import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  labels: any;
  navbarOpen: boolean;
  isEnglish: boolean;
  language: string;
  response: any;
  user: User;
  invalidUser: boolean = false;

  constructor(
    private _router: Router,
    private storageService: StorageService,
    private navBarService: NavBarService,
    private loginService: LoginService
  ) {
    this.storageService.getConfig();

    this.labels = {
      english: {
        navList: [
          {
            id: 1,
            name: 'Properties',
            icon: {
              prefix: 'fas',
              name: 'building'
            },
            url: '/properties',
            hasChildren: false,
            title: 'Available Properties',
            keywords: 'Properties, Available Properties, Real Estate, AMPICUN, AMPI Cancun',
            description: 'Available Properties in Quintana Roo'
          },
          {
            id: 2,
            name: 'Requests',
            icon: {
              prefix: 'fas',
              name: 'bookmark'
            },
            url: '/requests',
            title: 'Requests of Properties',
            hasChildren: false
          },
          {
            id: 3,
            name: 'Events',
            icon: {
              prefix: 'fas',
              name: 'calendar-day'
            },
            url: '/events',
            title: 'Events',
            hasChildren: false
          }
        ],
        login: 'Login',
        loginButton: 'Login',
        cancel: 'Cancel',
        logout: 'Logout',
        languageTitle: 'Español',
        userName: 'Username',
        password: 'Password',
        loginError: `Username or Password mismatch`
      },
      spanish: {
        navList: [
          {
            id: 1,
            name: 'Propiedades',
            icon: {
              prefix: 'fas',
              name: 'building'
            },
            url: '/propiedades',
            hasChildren: false,
            title: 'Propiedades disponibles',
            keywords: 'Bolsa Inmobiliaria, Bienes Raíces, AMPICUN, AMPI Cancún, Propiedades',
            description: 'Propiedades Disponibles en Quintana Roo'
          },
          {
            id: 2,
            name: 'Solicitudes',
            icon: {
              prefix: 'fas',
              name: 'bookmark'
            },
            url: '/solicitudes',
            title: 'Solicitudes de propiedades',
            hasChildren: false
          },
          {
            id: 3,
            name: 'Eventos',
            icon: {
              prefix: 'fas',
              name: 'calendar-day'
            },
            url: '/eventos',
            title: 'Eventos',
            hasChildren: false
          }
        ],
        login: 'Iniciar Sesión',
        loginButton: 'Aceptar',
        cancel: 'Cancelar',
        logout: 'Cerrar Sesión',
        languageTitle: 'English',
        userName: 'Nomber de usuario',
        password: 'Contraseña',
        loginError: 'El Nombre de usuario o Contraseña no coinciden'
      },
    };
    this.navbarOpen = false;
  }

  ngOnInit() {
    this.storageService.language$.subscribe(language => this.language = language);
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  changeLanguage() {
    const currentLanguage = this.language;
    this.storageService.changeLanguage();
    this.storageService.language$.subscribe(language => this.language = language);
    this.navBarService.setPageProperties(currentLanguage, this.language);
  }

  login(user: NgForm) {
    this.invalidUser = false;
    this.loginService.login(user.form.value).subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        if (this.response.error) {
          this.invalidUser = true;
          console.error('error', this.response.error);
          return;
        }

        $('#loginModal').modal('toggle');
        this.user = this.response.data;
        this.user.user_photo = this.user.member_photo ? this.user.member_photo : this.user.association_logo || 'members/no-photo.jpg';
        this.storageService.setItem('session', 'user', this.user)

        // const route = this.language === 'english' ? '/home' : '/inicio';
        const route = this.language === 'english' ? '/properties-list' : '/listado-propiedades';
        window.location.href = route;
      }
    });
  }
}
