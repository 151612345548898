import { Component, ElementRef } from '@angular/core';
import { LoginService } from './login/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isLogged: boolean;

  constructor (
    private el: ElementRef,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.isLogged = this.loginService.isAuthenticated();
  }

  ngAfterViewChecked() {
    this.onResize(event);
  }

  onResize(event) {
    const body = document.getElementById('body');
    if (!body) {
      return;
    }

    const deviceHeight = window.innerHeight;
    const headerHeight = this.el.nativeElement.offsetTop;
    const footerHeight = this.el.nativeElement.lastChild.childNodes[0].offsetHeight;
    body.style.minHeight = `${deviceHeight - headerHeight - footerHeight}px`;
  }
}
