import { Component, OnInit } from '@angular/core';
import { PropertiesService } from './properties.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StorageService } from '../storage/storage.service';
import { Property } from './property';
import { ShareProperty } from './share-property';
import { NavBarService } from './../nav-bar/nav-bar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralService } from '../services/general.service';
import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss']
})
export class PropertiesComponent implements OnInit {
  deviceInfo: any;
  labels: object;
  language: string;
  currentPage: number;
  itemsPerPage: number;
  totalPages: number;
  pagesPerBlock: number;
  firstPage: number;
  middlePages: Array<number>;
  lastPage: number;
  totalRecords: number;
  pageSeparatorLeft: boolean;
  pageSeparatorRight: boolean;
  keywords: string;
  propertyType: string;
  beds: number;
  baths: number;
  minPrice: number;
  maxPrice: number;
  response: any;
  propertyTypeList: Array<any>;
  properties: Array<Property>
  loading: boolean;
  imagesPath: string;
  currencyTypes: object;
  baseUrl: string;
  shareProperty: ShareProperty;
  includeAgentData: boolean;

  constructor (
    private _router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private propertiesService: PropertiesService,
    private navBarService: NavBarService,
    private deviceService: DeviceDetectorService,
    private generalService: GeneralService
  ) {
    this.labels = {
      english: {
        title: 'Available Properties',
        noResults: 'No property matches with the given info',
        keywords: 'Keywords',
        propertyType: 'Property Type',
        warehouse: 'Warehouse',
        house: 'House',
        apartment: 'Apartment',
        study: 'Study',
        building: 'Building',
        hotel: 'Hotel',
        shop: 'Shop',
        office: 'Office',
        pentgarden: 'Pent Garden',
        penthouse: 'Pent House',
        lot: 'Lot',
        lot_urbanized: 'Lot Urbanized',
        bedrooms: 'Bedrooms',
        bathrooms: 'Bathrooms',
        minPrice: 'Min Price',
        maxPrice: 'Max Price',
        search: 'Search',
        reference: 'Code',
        viewMore: 'View more',
        builtArea: 'Built Area',
        lotSize: 'Lot Size',
        garages: 'Parking',
        gym: 'Gym',
        pool: 'Pool',
        gate: 'Gate',
        loading: 'Loading...',
        viewDetails: 'View Details',
        detailsUrl: 'property/',
        shareTitle: 'Share Property Sheet',
        agentData: 'Include Agent Data',
        agent: 'agent',
        member: 'member'
      },
      spanish: {
        title: 'Propiedades Disponibles',
        noResults: 'Ninguna propiedad coincide con la información proporcionada',
        keywords: 'Palabras clave',
        propertyType: 'Tipo de Propiedad',
        warehouse: 'Bodega',
        house: 'Casa',
        apartment: 'Departamento',
        study: 'Estudio',
        building: 'Edificio',
        hotel: 'Hotel',
        shop: 'Local',
        office: 'Oficina',
        pentgarden: 'Pent Garden',
        penthouse: 'Pent House',
        lot: 'Terreno no urbanizado',
        lot_urbanized: 'Terreno urbanizado',
        bedrooms: 'Recámaras',
        bathrooms: 'Baños',
        minPrice: 'Precio Min',
        maxPrice: 'Precio Max',
        search: 'Buscar',
        reference: 'Ref.',
        viewMore: 'Ver más',
        builtArea: 'Construcción',
        lotSize: 'Terreno',
        garages: 'Garages',
        gym: 'Gimnasio',
        pool: 'Alberca',
        gate: 'Caseta',
        loading: 'Cargando...',
        viewDetails: 'Ver Detalles',
        detailsUrl: 'propiedad/',
        shareTitle: 'Compartir Ficha',
        agentData: 'Incluir datos del Agente',
        agent: 'agente',
        member: 'socio'
      }
    };

    this.shareProperty = null;
  }

  ngOnInit() {
    this.storageService.language$.subscribe(language => this.language = language);
    this.baseUrl = this.route.snapshot.routeConfig.path;
    const queryParams = this.route.snapshot.queryParams;
    this.navBarService.setCurrentRoute(this._router.url, this.language);
    this.currentPage = Number.parseInt(queryParams.page) || 1;
    this.propertyType = queryParams.propertyType || '';
    this.keywords = queryParams.keywords || '';
    this.beds = queryParams.beds || 0;
    this.baths = queryParams.baths || 0;
    this.minPrice = Number.parseInt(queryParams.minPrice) || 0;
    this.maxPrice = Number.parseInt(queryParams.maxPrice) || 0;
    this.itemsPerPage = 12;
    this.pagesPerBlock = 7;
    this.propertyTypeList = [];
    this.imagesPath = this.propertiesService.imagesUrl;
    this.currencyTypes = {
      "1": 'MXN',
      "2": 'USD'
    }
    this.includeAgentData = false;
    this.propertiesService.getPropertiesTypes().subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        if (this.response.totalRecords > 0) {
          this.propertyTypeList = this.response.propertiesTypes.map(item => {
            const type = {
              key: item.name,
              value: this.labels[this.language][item.name]
            };
            return type;
          });
        }
      }
    });

    this.getPropertiesList(this.currentPage);
  }

  getPropertiesList(page, isSearch = false) {
    this.totalPages = isSearch ? 1 : this.totalPages;
    this.loading = true;
    const filters = {
      page: page,
      itemsPerPage: this.itemsPerPage,
      filters: {
        keywords: this.keywords.replace(/\+/gi, '%2B').replace(/#/gi, '%23'),
        propertyType: this.propertyType,
        beds: this.beds,
        baths: this.baths,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice
      }
    };

    const activeFilters = [
      `page=${page}`
    ];
    for (const key in filters.filters) {
      if (filters.filters[key] != '' && filters.filters[key] != 0) {
        activeFilters.push(`${key}=${filters.filters[key]}`)
      }
    }
    const params = activeFilters.join('&');
    const newRoute = `${this.baseUrl}?${params}`;
    this._router.navigateByUrl(newRoute);

    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isDesktopDevice = this.deviceService.isDesktop();
    const whatsappBase = !isDesktopDevice ? 'https://api.whatsapp.com/send' : 'https://web.whatsapp.com/send';

    this.propertiesService.getProperties(filters).subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        this.loading = false;
        this.pageSeparatorLeft = false;
        this.pageSeparatorRight = false;
        this.properties = [];

        if (this.response.totalRecords > 0) {
          this.properties = [...this.response.data.properties];

          this.properties.forEach(item => {
            const phone = item.collaborator_phone ? item.collaborator_phone : (item.member_phone ? item.member_phone : item.company_phone);
            const message = `text=Hola, pudiera darme información de la propiedad _"${item.property_url}"_`;
            const whatsappMessage = `${whatsappBase}?phone=52${phone}&${message}`;
            item['contactPhone'] = phone;
            item['whatsappBase'] = whatsappBase;
            item['whatsappMessage'] = whatsappMessage;
            item['defaultPhoto'] = item['property_photo_path'] || item.photo_path;
            item['isExternal'] = ((item.keywords || '')
              .split(',')
              .filter(keyword => keyword.trim() === '#ext#')
            ).length === 1;
          });
          this.totalRecords = this.response.totalRecords;
          this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
          this.currentPage = page > this.totalPages ? this.totalPages : (page < 1 ? 1 : page);
          this.firstPage = 1;
          this.lastPage = this.totalPages;
          let startIndex = this.firstPage + 1;
          let endIndex = this.lastPage - 1;
          this.middlePages = [];

          if (this.totalPages > this.pagesPerBlock) {
            startIndex = this.currentPage < this.pagesPerBlock - 2 ? 2 : (this.currentPage > this.totalPages - (this.pagesPerBlock - 3) ? this.totalPages - (this.pagesPerBlock - 3) : this.currentPage - 1);
            endIndex = this.currentPage < this.pagesPerBlock - 2 ? this.pagesPerBlock - 2 : (this.currentPage > this.totalPages - (this.pagesPerBlock - 3) ? this.totalPages - 1 : this.currentPage + 1);
            this.pageSeparatorLeft = startIndex > 2;
            this.pageSeparatorRight = endIndex < this.totalPages - 1;
          }

          for (let i = startIndex; i <= endIndex; i++) {
            this.middlePages.push(i);
          }
        }
      } else {
        console.error(response);
      }
    }, error => {
      console.error(<any>error);
    });
  }

  onPageChange(page) {
    const queryParams = {...this.route.snapshot.queryParams};
    queryParams['page'] = page;
    const paramsArray = [];
    for (const key in queryParams) {
      paramsArray.push(`${key}=${queryParams[key]}`);
    }
    const params = paramsArray.join('&');
    const newRoute = `${this.baseUrl}?${params}`;
    this._router.navigateByUrl(newRoute);
    this.currentPage = page;
    this.getPropertiesList(page);
  }

  onSahreLink(property) {
    const propertyName = property.name.trim();
    const currency = this.currencyTypes[property.currency_id];
    const phone = property.collaborator_phone ? property.collaborator_phone : (property.member_phone ? property.member_phone : property.company_phone);
    const propertyPrice = `*Precio:* ${property.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${currency}`;
    const bedrooms = property.attributes.beds ? `%0A%0D*Recámaras:* ${property.attributes.beds}` : '';
    const bathrooms = property.attributes.baths ? `%0A%0D*Baños:* ${property.attributes.baths}` : '';
    const builtArea = property.attributes.built_area ? `%0A%0D*Construcción:* ${property.attributes.built_area.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} m2` : '';
    const lotSize = property.attributes.lot_size ? `%0A%0D*Terreno:* ${property.attributes.lot_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} m2` : '';
    const agentPhone = phone ? `%0A%0D*Tel:* ${phone}` : '';
    const assistantName = property.collaborator_name ? this.generalService.capitalizeAndTrim(property.collaborator_name) : '';
    const memberName = this.generalService.capitalizeAndTrim(property.member_name);
    const collaboratorName = assistantName.toLowerCase() !== memberName.toLowerCase() ? `%0A%0D*Agente:* ${assistantName}` : '';

    const agentName = property.listing_member_id ? `%0A%0D*Asesor:* ${property.member_name}${collaboratorName}${agentPhone}` : '';
    // const url = `https://ampicun.org/${this.labels[this.language].detailsUrl}${property.id}`;
    const url = `https://ficha.vip/ficha.php?id=${property.id}`;

    this.shareProperty = {
      body: `*_${propertyName}_*%0A%0D%0A%0D*Referencia:* ${property.id}%0A%0D${propertyPrice}${builtArea}${lotSize}${bedrooms}${bathrooms}`,
      agentId: property.contact_id,
      memberId: property.listing_member_id,
      agentName: agentName,
      agencyName: property.company_name.toUpperCase(),
      detailsSheet: url,
      whatsappBase: property.whatsappBase,
      propertyUrl: property.property_url
    };
    $('#share').modal('toggle');
  }

  shareTo(appName) {
    if (!this.shareProperty) {
      return;
    }

    let url = '';
    const agentInfo = this.includeAgentData ? `%26c=1` : '';
    this.shareProperty.body = appName !== 'whatsapp' ? this.shareProperty.body.replace(/\*/g, '').replace(/_/g, '') : this.shareProperty.body;
    const sharePropertySimple = `BOLSA INMOBILIARIA * OFRECE * VENTA%0A%0D%0A%0D${this.shareProperty.body}`;
    const sharePropertyAgent = `${this.shareProperty.agencyName} * OFREZCO * VENTA%0A%0D%0A%0D${this.shareProperty.body}%0A%0D${this.shareProperty.agentName}`;
    const sharePropertyMessage = this.includeAgentData ? sharePropertyAgent : sharePropertySimple;
    this.shareProperty.detailsSheet += agentInfo;

    switch (appName) {
      case 'whatsapp':
        url = `${this.shareProperty.whatsappBase}?text=${sharePropertyMessage}%0A%0D%0A%0D${this.shareProperty.detailsSheet}`;
        break;
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${this.shareProperty.detailsSheet}`;
        break;
      case 'twitter':
        url = `http://www.twitter.com/share?url=${this.shareProperty.detailsSheet}&text=${sharePropertyMessage}%0A%0D%0A%0D`;
        break;
    }

    window.open(url);
    $('#share').modal('toggle');
  }
}
