import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import { StorageService } from './../../storage/storage.service';
import { Property } from './../property';
import { Agent } from './../../agents/agent';
import { PropertiesService } from './../properties.service';
import { NavBarService } from './../../nav-bar/nav-bar.service';
import { Title, Meta } from '@angular/platform-browser';
import { MetafrenzyService } from 'ngx-metafrenzy';

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss']
})
export class PropertyComponent implements OnInit {
  id: number;
  agentId: number;
  labels: object;
  language: string;
  response: any;
  loading: boolean;
  property: Property;
  agent: Agent;
  currencyTypes: object;
  imagesPath: string;
  photos: Array<string>;
  goBackUrl: string;
  agentType: string;

  constructor(
    private _router: Router,
    private storageService: StorageService,
    private propertiesService: PropertiesService,
    private navBarService: NavBarService,
    private route: ActivatedRoute,
    private pageTitle: Title,
    private pageMeta: Meta,
    private readonly metafrenzyService: MetafrenzyService
  ) {
    this.labels = {
      english: {
        title: 'Available Properties',
        noResults: 'No property matches with the given info',
        reference: 'Code',
        propertyType: 'Property Type',
        bedrooms: 'Bedrooms',
        bathrooms: 'Bathrooms',
        builtArea: 'Built Area',
        lotSize: 'Lot Size',
        warehouse: 'Warehouse',
        house: 'House',
        apartment: 'Apartment',
        study: 'Study',
        building: 'Building',
        hotel: 'Hotel',
        shop: 'Shop',
        office: 'Office',
        pentgarden: 'Pent Garden',
        penthouse: 'Pent House',
        lot: 'Lot',
        lot_urbanized: 'Lot Urbanized',
        garages: 'Parking',
        gym: 'Gym',
        pool: 'Pool',
        gate: 'Gate',
        loading: 'Loading...',
        viewDetails: 'View Details',
        propertiesUrl: '/properties',
        goBack: 'Available Properties',
        agent: 'agent',
        member: 'member',
        listedBy: 'Listed by Agent',
        features: 'Features'
      },
      spanish: {
        title: 'Propiedades Disponibles',
        noResults: 'Ninguna propiedad coincide con la información proporcionada',
        reference: 'Ref.',
        propertyType: 'Tipo de Propiedad',
        bedrooms: 'Recámaras',
        bathrooms: 'Baños',
        builtArea: 'Construcción',
        lotSize: 'Terreno',
        warehouse: 'Bodega',
        house: 'Casa',
        apartment: 'Departamento',
        study: 'Estudio',
        building: 'Edificio',
        hotel: 'Hotel',
        shop: 'Local',
        office: 'Oficina',
        pentgarden: 'Pent Garden',
        penthouse: 'Pent House',
        lot: 'Terreno no urbanizado',
        lot_urbanized: 'Terreno urbanizado',
        garages: 'Garages',
        gym: 'Gimnasio',
        pool: 'Alberca',
        gate: 'Caseta',
        loading: 'Cargando...',
        viewDetails: 'Ver Detalles',
        propertiesUrl: '/propiedades',
        goBack: 'Propiedades Disponibles',
        agent: 'agente',
        member: 'socio',
        listedBy: 'Listado por el(la) agente',
        features: 'Características'
      }
    };
  }

  ngOnInit() {
    this.storageService.language$.subscribe(language => this.language = language);
    this.navBarService.setCurrentRoute(this._router.url, this.language);
    const previousUrl = document.referrer;
    const propertiesUrl = this.labels[this.language].propertiesUrl;
    this.goBackUrl = previousUrl && previousUrl.includes(propertiesUrl) ? previousUrl : propertiesUrl;
    this.currencyTypes = {
      "1": 'MXN',
      "2": 'USD'
    }
    this.loading = true;
    this.photos = [];
    this.property = null;
    this.agent = null;
    this.imagesPath = this.propertiesService.imagesUrl;
    this.id = Number.parseInt(this.route.snapshot.paramMap.get("propertyId"));
    this.propertiesService.getProperty(this.id).subscribe(response => {
      this.response = response;
      this.loading = false;
      
      if (this.response.code === 200) {
        if (this.response.record_found) {
          this.property = this.response.data.property[0];
          this.property.property_type_name = this.property.property_type_name.toLocaleLowerCase();
          this.photos = this.property.photos ? this.property.photos.split(',') : [];
          if (!this.photos.length) {
            this.photos.push(this.property.no_photo);
          }
          this.pageTitle.setTitle(this.property.name);
          const metaDescription = this.pageMeta.getTags('name=description');
          metaDescription[0].content = this.property.description;
          const author = this.pageMeta.getTags('name=author');
          author[0].content = this.property.description;
          this.metafrenzyService.setMetaTag('author', 'website');
          this.metafrenzyService.setMetaTag('og:type', 'website');
          this.metafrenzyService.setMetaTag('og:title', this.property.name);
          this.metafrenzyService.setMetaTag('og:description', this.property.description);
          this.metafrenzyService.setMetaTag('og:image', `${this.imagesPath}${this.photos[0]}`);
          this.metafrenzyService.setMetaTag('og:image:type', 'image/jpeg');
          this.metafrenzyService.setMetaTag('og:image:width', '300');
          this.metafrenzyService.setMetaTag('og:image:height', '300');
        }
      }
    });

    this.agentId = Number.parseInt(this.route.snapshot.paramMap.get("agentId"));
    if (this.agentId) {
      this.loading = false;
      this.agentType = this.route.snapshot.url[2].path === this.labels[this.language].agent ? 'agent' : 'member';
      this.propertiesService.getAgent(this.agentType, this.agentId).subscribe(response => {
        this.response = response;
        this.loading = false;

        if (this.response.code === 200) {
          if (this.response.record_found) {
            const agent = this.response.data[0];
            const name = agent.agent_name;
            const photo = agent.member_photo ? agent.member_photo : agent.company_logo;
            const website = agent.member_website ? agent.member_website : agent.company_website;
            const email = agent.collaborator_email ? agent.collaborator_email : (agent.member_email ? agent.member_email : agent.company_email);
            const phone = agent.collaborator_phone ? agent.collaborator_phone : (agent.member_phone ? agent.member_phone : agent.company_phone);
            this.agent = {
              member: '',
              name: name,
              photo: photo,
              website: website,
              email: email,
              phone: phone
            };
          }
        }
      });
    }
  }
}
