import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class NavBarService {
  routeData: object;
  currentRoute: string;
  routes: object;

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private pageTitle: Title,
    private pageMeta: Meta
  ) {
    this.routes = {
      english: [
        {
          name: 'Properties',
          url: '/properties',
          title: 'Available Properties',
          keywords: 'Properties, Available Properties, Real Estate, AMPICUN, AMPI Cancun',
          description: 'Available Properties in Quintana Roo'
        },
        {
          name: 'Property',
          url: '/property/',
          title: '',
          keywords: 'Properties, Available Properties, Real Estate, AMPICUN, AMPI Cancun',
          description: ''
        },
        {
          name: 'Requests',
          url: '/requests',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Events',
          url: '/events',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Event',
          url: '/event/',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Home',
          url: '/',
          title: 'Available Properties',
          keywords: 'Properties, Available Properties, Real Estate, AMPICUN, AMPI Cancun',
          description: 'Available Properties in Quintana Roo'
        }
      ],
      spanish: [
        {
          name: 'Propiedades',
          url: '/propiedades',
          title: 'Propiedades disponibles',
          keywords: 'Bolsa Inmobiliaria, Bienes Raíces, AMPICUN, AMPI Cancún, Propiedades',
          description: 'Propiedades Disponibles en Quintana Roo'
        },
        {
          name: 'Propiedad',
          url: '/propiedad/',
          title: '',
          keywords: 'Bolsa Inmobiliaria, Bienes Raíces, AMPICUN, AMPI Cancún, Propiedades',
          description: ''
        },
        {
          name: 'Solicitudes',
          url: '/solicitudes',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Eventos',
          url: '/eventos',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Evento',
          url: '/evento/',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Home',
          url: '/',
          title: 'Propiedades disponibles',
          keywords: 'Bolsa Inmobiliaria, Bienes Raíces, AMPICUN, AMPI Cancún, Propiedades',
          description: 'Propiedades Disponibles en Quintana Roo'
        }
      ]
    }
  }

  setCurrentRoute(route, currentLanguage) {
    this.currentRoute = route;
    this.setPageProperties(currentLanguage)
  }

  setPageProperties(currentLanguage, newLanguage = null) {
    newLanguage = newLanguage || currentLanguage;
    const pathArray = this.currentRoute.split('/');
    const path = pathArray.length > 2 ? pathArray[pathArray.length - 1] : '';
    const queryParams = {...this.route.snapshot.queryParams};
    const paramsArray = [];

    for (const key in queryParams) {
      paramsArray.push(`${key}=${queryParams[key]}`);
    }
    const params = paramsArray.join('&');

    const routeIndex = this.routes[currentLanguage].findIndex(item => {
      return this.currentRoute.includes(item.url)
    });

    const routeData = this.routes[newLanguage][routeIndex].url !== '/' ? this.routes[newLanguage][routeIndex] : this.routes[newLanguage][0];
    this.currentRoute = routeData.url + path + '?' + params;

    this.pageTitle.setTitle(routeData.title);
    this.pageMeta.addTag({
      name: 'keywords',
      content: routeData.keywords
    });
    this.pageMeta.addTag({
      name: 'description',
      content: routeData.description
    });
    this.pageMeta.addTag({
      name: 'viewport',
      content: 'width=device-width, initial-scale=1'
    });

    if (currentLanguage !== newLanguage) {
      this._router.navigateByUrl(this.currentRoute);
    }
  }
}
