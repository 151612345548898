import { NgModule } from '@angular/core';
import { BrowserModule, Title, Meta } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AngularWebStorageModule } from 'angular-web-storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { EllipsisModule } from 'ngx-ellipsis';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxPaginationModule } from 'ngx-pagination';
// import { MetaModule, MetaLoader, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';
import { MetafrenzyModule } from 'ngx-metafrenzy';
import { RouterModule } from '@angular/router';

import { CustomCurrencyPipe } from './pipes/currency.pipe';

import { StorageService } from './storage/storage.service'
import { NavBarService } from './nav-bar/nav-bar.service';
import { GeneralService } from './services/general.service';

import { AppComponent } from './app.component';
import { ErrorComponent } from './error/error.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { PropertiesComponent } from './properties/properties.component';
import { PropertyComponent } from './properties/property/property.component';
import { RequestsComponent } from './requests/requests.component';
import { RequestComponent } from './requests/request/request.component';
import { FooterComponent } from './footer/footer.component';
import { PhonePipe } from './pipes/phone.pipe';
import { NavSideBarComponent } from './nav-side-bar/nav-side-bar.component';
import { UserPropertiesComponent } from './users/properties/user-properties/user-properties.component';
import { UserRequestsComponent } from './users/requests/user-requests/user-requests.component';
import { UserEventsComponent } from './users/events/user-events/user-events.component';
import { UserProfileComponent } from './users/profile/user-profile/user-profile.component';
import { UserDashbordComponent } from './users/dashboard/user-dashbord/user-dashbord.component';
import { PropertyFormComponent } from './users/properties/user-properties/property-form/property-form.component';
import { RequestFormComponent } from './users/requests/user-requests/request-form/request-form.component';
import { EventFormComponent } from './users/events/user-events/event-form/event-form.component';
import { MembersListComponent } from './users/events/user-events/members-list/members-list.component';
import { UserMembersComponent } from './users/members/user-members/user-members.component';
import { MemberFormComponent } from './users/members/user-members/member-form/member-form.component';
import { CatalogComponent } from './users/catalog/catalog.component';
import { UserFormComponent } from './users/catalog/user-form/user-form.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { EventsModule } from './events/events.module';
import { EventsComponent } from './events/events.component';
import { EventComponent } from './events/event/event.component';

@NgModule({
  declarations: [
    AppComponent,
    PropertiesComponent,
    ErrorComponent,
    NavBarComponent,
    FooterComponent,
    RequestsComponent,
    PropertyComponent,
    RequestComponent,
    CustomCurrencyPipe,
    PhonePipe,
    NavSideBarComponent,
    UserPropertiesComponent,
    UserRequestsComponent,
    UserEventsComponent,
    UserProfileComponent,
    UserDashbordComponent,
    PropertyFormComponent,
    RequestFormComponent,
    EventFormComponent,
    UserMembersComponent,
    MemberFormComponent,
    CatalogComponent,
    UserFormComponent,
    MembersListComponent,
    DragDropDirective,
    EventsComponent,
    EventComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    AngularWebStorageModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FontAwesomeModule,
    EllipsisModule,
    DeviceDetectorModule.forRoot(),
    NgxPaginationModule,
    MetafrenzyModule.forRoot(),
    RouterModule,
    EventsModule
  ],
  providers: [
    Title,
    Meta,
    StorageService,
    NavBarService,
    GeneralService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    library.add(fas, far, fab);
  }
}
