import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { sharedUrls } from './../shared/base-url';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {
  apiUrl: string;
  imagesUrl: string;

  constructor (
    private httpClient: HttpClient
  ) {
    const baseUrl = environment.baseUrl;
    this.apiUrl = `${baseUrl}${sharedUrls.apiUrl}`;
    this.imagesUrl = environment.imagesUrl;
  }

  getRequests() {
    const url = `${this.apiUrl}/requests`;
    return this.httpClient.get(url);
  }

  getRequest(id) {
    const url = `${this.apiUrl}/request/${id}`;
    return this.httpClient.get(url);
  }

  getAgent(type, id) {
    const url = `${this.apiUrl}/agent`;
    const agent = {
      type: type,
      id: id
    };
    let json = JSON.stringify(agent);
    let params = new HttpParams().set('json', json);
    return this.httpClient.get(url, { params: params });
  }

  saveRequest(id, data) {
    const putParam = id ? `/${id}` : ''
    const url = `${this.apiUrl}/request${putParam}`;
    const json = JSON.stringify(data);
    const params = new HttpParams().set('json', json);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    if (!id) {
      return this.httpClient.post(url, params, { headers: headers });
    }
    return this.httpClient.put(url, params, { headers: headers });
  }

  deleteRequest(id) {
    const url = `${this.apiUrl}/request/${id}`;
    return this.httpClient.delete(url);
  }
}
