import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/storage/storage.service';
import { UserDashboardService } from './user-dashboard.service';
import { User } from 'src/app/login/user';
import { UserDashboard } from './user-dashboard';
import { Router } from '@angular/router';
import { NavSideBarService } from 'src/app/nav-side-bar/nav-side-bar.service';

@Component({
  selector: 'app-user-dashbord',
  templateUrl: './user-dashbord.component.html',
  styleUrls: ['./user-dashbord.component.scss']
})
export class UserDashbordComponent implements OnInit {
  labels: any;
  language: string;
  user: User;
  response: any;
  dashboards: UserDashboard;
  loading: boolean;

  constructor(
    private _router: Router,
    private navSideBarService: NavSideBarService,
    private storageService: StorageService,
    private dashboardsService: UserDashboardService
  ) {
    this.labels = {
      english: {
        title: 'Dashboard',
        properties: 'Properties',
        requests: 'Requests',
        events: 'Events Assistance',
        members: 'Members',
        agents: 'Agents'
      },
      spanish: {
        title: 'Estadísticas',
        properties: 'Propiedades',
        requests: 'Solicitudes',
        events: 'Asistencia a Eventos',
        members: 'Socios',
        agents: 'Agentes'
      }
    }

    this.dashboards = {
      total_properties: 0,
      total_requests: 0,
      total_events_assisted: 0,
      total_members: 0,
      total_collaborators: 0
    };
  }

  ngOnInit() {
    this.storageService.language$.subscribe(language => this.language = language);
    this.user = this.storageService.getItem('session', 'user');
    this.navSideBarService.setCurrentRoute(this._router.url, this.language);
    this.loading = true;

    const filters = {
      userType: this.user.user_type,
      userGroup: this.user.user_type_group,
      associationId: this.user.association_id,
      memberId: this.user.member_id,
      companyId: this.user.company_id
    }

    this.dashboardsService.getDashboards(filters).subscribe(response => {
      this.response = response;

      if (this.response.code === 200) {
        this.loading = false;
        this.dashboards = {
          total_properties: 0,
          total_requests: 0,
          total_events_assisted: 0,
          total_members: 0,
          total_collaborators: 0
        };

        if (this.response.totalRecords > 0) {
          this.dashboards = this.response.data;
        }
      }
    })
  }

}
