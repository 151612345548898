import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/storage/storage.service';
import { PropertiesService } from 'src/app/properties/properties.service'
import { RequestsService } from 'src/app/requests/requests.service'
import { Property } from 'src/app/properties/property';
import { User } from 'src/app/login/user';
import { Router } from '@angular/router';
import { NavSideBarService } from 'src/app/nav-side-bar/nav-side-bar.service';

@Component({
  selector: 'app-user-requests',
  templateUrl: './user-requests.component.html',
  styleUrls: ['./user-requests.component.scss']
})
export class UserRequestsComponent implements OnInit {
  labels: any;
  language: string;
  response: any;
  loading: boolean;
  requests: Array<Property>;
  totalRecords: number;
  totalPages: number;
  currentPage: number;
  itemsPerPage: number;
  pagesPerBlock: number;
  keywords: string;
  imagesPath: string;
  user: User;

  constructor(
    private _router: Router,
    private navSideBarService: NavSideBarService,
    private storageService: StorageService,
    private propertiesService: PropertiesService,
    private requestsService: RequestsService
  ) {
    this.labels = {
      english: {
        title: 'Requests List',
        reference: 'Code',
        name: 'Title',
        propertyType: 'Property Type',
        companyName: 'Agency',
        photo: 'Photo',
        actions: 'Actions',
        showDetails: 'Show Details',
        hideDetails: 'Hide Details',
        contact: 'Contact',
        description: 'Description',
        warehouse: 'Warehouse',
        house: 'House',
        apartment: 'Apartment',
        study: 'Study',
        building: 'Building',
        hotel: 'Hotel',
        shop: 'Shop',
        office: 'Office',
        pentgarden: 'Pent Garden',
        penthouse: 'Pent House',
        lot: 'Lot',
        lot_urbanized: 'Lot Urbanized',
        keywords: 'Keywords',
        loading: 'Loading',
        noResults: 'No request matches with the given info',
        editLink: 'edit-request'
      },
      spanish: {
        title: 'Lista de Solicitudes',
        reference: 'Ref.',
        name: 'Título',
        propertyType: 'Tipo de Propiedad',
        companyName: 'Agencia',
        photo: 'Foto',
        actions: 'Acciones',
        showDetails: 'Ver Detalles',
        hideDetails: 'Ocultar Detalles',
        contact: 'Contacto',
        description: 'Descripción',
        warehouse: 'Bodega',
        house: 'Casa',
        apartment: 'Departamento',
        study: 'Estudio',
        building: 'Edificio',
        hotel: 'Hotel',
        shop: 'Local',
        office: 'Oficina',
        pentgarden: 'Pent Garden',
        penthouse: 'Pent House',
        lot: 'Terreno no urbanizado',
        lot_urbanized: 'Terreno urbanizado',
        keywords: 'Palabras clave',
        loading: 'Cargando',
        noResults: 'Ninguna solicitud coincide con la información proporcionada',
        editLink: 'editar-solicitud'
      }
    }
  }

  ngOnInit() {
    this.storageService.language$.subscribe(language => this.language = language);
    this.user = this.storageService.getItem('session', 'user');
    this.imagesPath = this.propertiesService.imagesUrl;
    this.navSideBarService.setCurrentRoute(this._router.url, this.language);
    this.loading = true;
    this.totalRecords = 0;
    this.totalPages = 0;
    this.currentPage = 1;
    this.itemsPerPage = 10;
    this.pagesPerBlock = 7;

    this.requestsService.getRequests().subscribe(response => {
      this.response = response;

      if (this.response.code === 200) {
        this.loading = false;
        this.requests = [];

        if (this.response.totalRecords > 0) {
          this.requests = this.response.data.map(item => {
            item.contactName = item.agent_name ? item.agent_name : item.member_name;
            item.contactPhone = item.collaborator_phone ? item.collaborator_phone : (item.member_phone ? item.member_phone : item.company_phone);
            item.showDetails = false;
            item.property_type_name = this.labels[this.language][item.property_type_name.toLowerCase()];
            item.editable = this.user.user_type === 'Admin' && ['Super', 'Admin'].includes(this.user.user_type_group) || 
              (this.user.user_type === 'Admin' && this.user.user_type_group === 'Association' && this.user.association_id == item.association_id) || 
              (this.user.user_type !== 'Admin' && this.user.member_id == item.member_id);
            item.editableLink = item.editable ? `./${this.labels[this.language].editLink}/${item.id}` : ''
            return item;
          });
          this.totalRecords = this.response.totalRecords;
          this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
        }
      }
    });
  }

  filterResults(value) {
    if (!this.requests) {
      return;
    }

    if (!value) {
      return this.requests;
    }

    value = value.toLowerCase();

    const filteredResults = this.requests.filter(item => {
      return (item.company_name.toLowerCase().includes(value) 
        || item.name.toLowerCase().includes(value) 
        || item.property_type_name.toLowerCase().includes(value) 
        || item.description.toLowerCase().includes(value) 
        || item.contactName.toLowerCase().includes(value))
    });
    return filteredResults;
  }

  toggleDetails(item) {
    item.showDetails = !item.showDetails;
  }
}
