import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class NavSideBarService {
  routeData: object;
  currentRoute: string;
  routes: object;

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private pageTitle: Title,
    private pageMeta: Meta
  ) {
    this.routes = {
      english: [
        {
          name: 'Dashboard',
          url: '/home',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Users List',
          url: '/users-list',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'New User',
          url: '/new-user',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Members List',
          url: '/members-list',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'New Member',
          url: '/new-member',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Properties List',
          url: '/properties-list',
          title: 'Available Properties',
          keywords: 'Properties, Available Properties, Real Estate, AMPICUN, AMPI Cancun',
          description: 'Available Properties in Quintana Roo'
        },
        {
          name: 'New Property',
          url: '/new-property',
          title: '',
          keywords: 'Properties, Available Properties, Real Estate, AMPICUN, AMPI Cancun',
          description: ''
        },
        {
          name: 'Edit Property',
          url: '/edit-property',
          title: '',
          keywords: 'Properties, Available Properties, Real Estate, AMPICUN, AMPI Cancun',
          description: ''
        },
        {
          name: 'Requests List',
          url: '/requests-list',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'New Request',
          url: '/new-request',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Edit Request',
          url: '/edit-request',
          title: '',
          keywords: 'Bolsa Inmobiliaria, Bienes Raíces, AMPICUN, AMPI Cancún, Propiedades',
          description: ''
        },
        {
          name: 'Events List',
          url: '/events-list',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'New Event',
          url: '/new-event',
          title: '',
          keywords: '',
          description: ''
        }
      ],
      spanish: [
        {
          name: 'Estadísticas',
          url: '/inicio',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Lista de Usuarios',
          url: '/listado-usuarios',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Nuevo Usuario',
          url: '/nuevo-usuario',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Lista de Socios',
          url: '/listado-socios',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Nuevo Socio',
          url: '/nuevo-socio',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Lista de Propiedades',
          url: '/listado-propiedades',
          title: 'Propiedades disponibles',
          keywords: 'Bolsa Inmobiliaria, Bienes Raíces, AMPICUN, AMPI Cancún, Propiedades',
          description: 'Propiedades Disponibles en Quintana Roo'
        },
        {
          name: 'Nueva Propiedad',
          url: '/nueva-propiedad',
          title: '',
          keywords: 'Bolsa Inmobiliaria, Bienes Raíces, AMPICUN, AMPI Cancún, Propiedades',
          description: ''
        },
        {
          name: 'Editar Propiedad',
          url: '/editar-propiedad',
          title: '',
          keywords: 'Bolsa Inmobiliaria, Bienes Raíces, AMPICUN, AMPI Cancún, Propiedades',
          description: ''
        },
        {
          name: 'Lista de Solicitudes',
          url: '/listado-solicitudes',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Nueva Solicitud',
          url: '/nueva-solicitud',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Editar Solicitud',
          url: '/editar-solicitud',
          title: '',
          keywords: 'Bolsa Inmobiliaria, Bienes Raíces, AMPICUN, AMPI Cancún, Propiedades',
          description: ''
        },
        {
          name: 'Lista de Eventos',
          url: '/listado-eventos',
          title: '',
          keywords: '',
          description: ''
        },
        {
          name: 'Nuevo Evento',
          url: '/nuevo-evento',
          title: '',
          keywords: '',
          description: ''
        }
      ]
    }
  }

  setCurrentRoute(route, currentLanguage) {
    this.currentRoute = route;
    this.setPageProperties(currentLanguage)
  }

  setPageProperties(currentLanguage, newLanguage = null) {
    newLanguage = newLanguage || currentLanguage;
    const pathArray = this.currentRoute.split('/');
    const path = pathArray.length > 2 ? pathArray[pathArray.length - 1] : '';
    const queryParams = {...this.route.snapshot.queryParams};
    const paramsArray = [];

    for (const key in queryParams) {
      paramsArray.push(`${key}=${queryParams[key]}`);
    }
    const params = paramsArray.join('&');

    const routeIndex = this.routes[currentLanguage].findIndex(item => {
      return this.currentRoute.includes(item.url)
    });

    const routeData = this.routes[newLanguage][routeIndex].url !== '/' ? this.routes[newLanguage][routeIndex] : this.routes[newLanguage][0];
    this.currentRoute = routeData.url + path + '?' + params;

    this.pageTitle.setTitle(routeData.title);
    this.pageMeta.addTag({
      name: 'keywords',
      content: routeData.keywords
    });
    this.pageMeta.addTag({
      name: 'description',
      content: routeData.description
    });
    this.pageMeta.addTag({
      name: 'viewport',
      content: 'width=device-width, initial-scale=1'
    });

    if (currentLanguage !== newLanguage) {
      this._router.navigateByUrl(this.currentRoute);
    }
  }
}
