import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value, currency?: string) {
    const valueFormated = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const currencyValue = currency || '';
    return `$ ${valueFormated} ${currencyValue}`;
  }

}
