import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/login/user';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NavBarService } from './../nav-bar/nav-bar.service';
import { StorageService } from 'src/app/storage/storage.service';
import { UserMembersService } from './../users/members/user-members/user-members.service';
import { UserEventsService } from './../users/events/user-events/user-events.service';
import { Association } from './../users/members/user-members/association';
import { Event } from './../users/events/user-events/event';
import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  labels: any;
  language: string;
  loading: boolean;
  eventTypes: Array<any>;
  events: Array<Event>;
  eventsFiltered: Array<Event>;
  eventType: number;
  associationId: number;
  keywords: string;
  totalRecords: number;
  totalPages: number;
  currentPage: number;
  itemsPerPage: number;
  pagesPerBlock: number;
  response: any;
  responseUpload: any;
  associations: Array<Association>
  eventPeriod: number;
  imagesPath: string;
  selectedEvent: any;
  alertType: string;
  alertIcon: Array<string>;
  alertMessage: string;
  files: Array<any> = [];
  filesErrorMessage: string = '';
  agreements: string;

  constructor(
    private _router: Router,
    private navBarService: NavBarService,
    private storageService: StorageService,
    private userEventsService: UserEventsService,
    private userMembersService: UserMembersService
  ) {
    this.labels = {
      english: {
        title: 'Events List',
        loading: 'Loading',
        commingSoon: 'Coming soon...',
        monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        monthsLong: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        allAssociations: 'All Associations',
        noAssociation: 'Without Association',
        allEvents: 'All Events',
        showActiveEvents: 'Active Events',
        showPastEvents: 'Past Events',
        keywords: 'Keywords',
        eventName: 'Name',
        eventType: 'Type',
        eventDate: 'Date',
        eventTime: 'Time',
        eventDuration: 'Duration',
        eventValidTime: 'Valid Time',
        eventClosed: 'Closed',
        eventAgreements: 'Agreements',
        eventAgenda: 'Agenda',
        actions: 'Actions',
        showDetails: 'Show Details',
        hideDetails: 'Hide Details',
        days: 'days',
        signed: 'Signed',
        references: 'Codes',
        referencesShort: 'Codes',
        codesPlaceHolder: `Property code`,
        checkIn: 'Check-In',
        checkOut: 'Check-Out',
        yes: 'Yes',
        no: 'No',
        noResults: 'No event matches with the given info',
        signedMembersList: 'Signed Members List',
        assistanceList: 'Assistance List',
        showSignedMembersList: 'Show Signed Members List',
        showAssistanceList: 'Show Assistance List',
        signedInSuccess: `You've been signed in!`,
        signedOutSuccess: `You've cancelled your registration!`,
        codesRegistered: `Your codes has been registered!`,
        checkedInSuccess: `Your check-in has been registered!`,
        checkedOutSuccess: `It has been registered your check-out!`,
        search: 'Search',
        closeEvent: 'Close Event',
        dragNDropLabel: 'Drag-and-drop here the images to upload. Only PNG or JPG files are allowed.',
        imagesAllInvalid: 'No file matches with an allowed image file type.',
        imagesSomeInvalid: `Some files didn't match with an allowed image file type.`,
        save: 'Save',
        cancel: 'Cancel'
      },
      spanish: {
        title: 'Lista de Eventos',
        loading: 'Cargando',
        commingSoon: 'Próximamente...',
        monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        monthsLong: [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre'
        ],
        allAssociations: 'Todas las Asociaciones',
        noAssociation: 'Sin Asociación',
        allEvents: 'Todos los eventos',
        showActiveEvents: 'Eventos activos',
        showPastEvents: 'Eventos pasados',
        keywords: 'Palabras clave',
        eventName: 'Nombre',
        eventType: 'Tipo',
        eventDate: 'Fecha',
        eventTime: 'Hora',
        eventDuration: 'Duración',
        eventValidTime: 'Tiempo Válido',
        eventClosed: 'Clausura',
        eventAgreements: 'Acuerdos',
        eventAgenda: 'Orden del Día',
        actions: 'Acciones',
        showDetails: 'Ver Detalles',
        hideDetails: 'Ocultar Detalles',
        days: 'días',
        signed: 'Registrado',
        references: 'Referencias',
        referencesShort: 'Refs.',
        codesPlaceHolder: `Ref. de propiedad`,
        checkIn: 'Asistencia',
        checkOut: 'Salida',
        yes: 'Sí',
        no: 'No',
        noResults: 'Ningún evento coincide con la información proporcionada',
        signedMembersList: 'Miembros Registrados',
        assistanceList: 'Lista de Asistencia',
        showSignedMembersList: 'Ver Miembros Registrados',
        showAssistanceList: 'Ver Lista de Asistencia',
        signedInSuccess: `Usted ha sido registrado`,
        signedOutSuccess: `Ha cancelado su registro`,
        codesRegistered: `Sus referencias han sido registradas`,
        checkedInSuccess: `Su asistencia ha sido registrada`,
        checkedOutSuccess: `Se ha registrado su salida`,
        search: 'Buscar',
        closeEvent: 'Clausurar Evento',
        dragNDropLabel: 'Arrastre y suelte aquí las imágenes a cargar. Sólo se admiten archivos PNG o JPG.',
        imagesAllInvalid: 'Ningún archivo corresponde a una imagen válida.',
        imagesSomeInvalid: 'Algunos archivos no corresponden a tipos de imágenes válidas.',
        save: 'Guardar',
        cancel: 'Cancelar'
      }
    }
  }

  ngOnInit() {
    this.storageService.language$.subscribe(language => this.language = language);
    this.navBarService.setCurrentRoute(this._router.url, this.language);
    this.imagesPath = this.userEventsService.imagesUrl;
    this.associationId = 2;
    this.eventType = 0;
    this.eventPeriod = 1;
    this.totalRecords = 0;
    this.totalPages = 0;
    this.currentPage = 1;
    this.itemsPerPage = 10;
    this.pagesPerBlock = 7;
    this.selectedEvent = {};

    this.userMembersService.getAssociationsList().subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        if (this.response.totalRecords > 0) {
          this.associations = [...this.response.data];
        }
      } else {
        console.error(response);
      }
    }, error => {
      console.error(<any>error);
    });

    this.getEventTypes();
    this.getEventsList();
  }

  getEventTypes () {
    this.eventTypes = [];
    const filters = {
      isPublicView: false
    };

    this.userEventsService.getEventTypes(filters).subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        if (this.response.totalRecords > 0) {
          this.eventTypes = [...this.response.data];
        }
      } else {
        console.error(response);
      }
    }, error => {
      console.error(<any>error);
    });
  }

  formatDate(date) {
    const dateArray = date.split('-');
    const year = dateArray[0];
    const month = Number.parseInt(dateArray[1]);
    const day = dateArray[2];
    const monthText = this.labels[this.language].monthsShort[month - 1];
    return this.language === 'english' ? `${monthText}-${day}-${year}` : `${day}-${monthText}-${year}`;
  }

  formatTime(time) {
    if (time === 0) {
      return '';
    }

    const validTimeHours = Math.floor(time / 60);
    const validTimeMinutes = Math.floor(time % 60);
    const validTimeSlots = [];

    if (validTimeHours) {
      validTimeSlots.push(`${validTimeHours} ${validTimeHours === 1 ? 'hr.' : 'hrs.'}`);
    }
    if (validTimeMinutes) {
      validTimeSlots.push(`${validTimeMinutes} ${validTimeMinutes === 1 ? 'min.' : 'mins.'}`);
    }

    return validTimeSlots.join(' ');
  }

  getEventsList() {
    this.loading = true;
    this.currentPage = 1;
    this.events = [];
    this.eventsFiltered = [];
    const filters = this.associationId < 0 ? {} : {
      associationId: this.associationId,
      showActiveEvents: true,
      showPastEvents: false,
      isPublicView: false,
      memberId: '',
      eventType: this.eventType
    };

    this.userEventsService.getEventsList(filters).subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        this.loading = false;

        if (this.response.totalRecords > 0) {
          this.events = this.response.data.map(item => {
            const currentDate = new Date();
            const eventDate = new Date(item.start_date);
            const dateArray = item.start_date.split('-');
            const year = dateArray[0];
            const month = Number.parseInt(dateArray[1]);
            const day = dateArray[2];
            const currentTime = `${currentDate.getHours()}:${currentDate.getMinutes()}`

            item.nameHighlighted = item.name;
            item.agreementsHighlighted = item.agreements;
            item.type = item.type.toLowerCase();
            item.start_date_formated = this.formatDate(item.start_date);
            item.duration_formated = this.formatTime(item.duration_in_minutes);
            item.valid_time_formated = this.formatTime(item.valid_time_in_minutes);
            item.start_time = `${item.start_time.substring(0, 5)} hrs.`;
            item.showDetails = false;
            item.member_left = item.member_left > 0;
            item.agenda = item.agenda.replace(/(?:\r\n|\r|\n)/g, '<br>');
            item.isActive = eventDate >= currentDate;
            item.isOpenForCheckIn = currentDate.getFullYear() === year 
              && currentDate.getMonth() + 1 === month 
              && currentDate.getDate() === Number.parseInt(day)
              && currentTime <= item.start_time.substring(0, 5);
            const codesArray = item.member_codes.split(',');
            item.member_code1 = codesArray[0] || '';
            item.member_code2 = codesArray[1] || '';
            item.member_code3 = codesArray[2] || '';
            return item;
          });
          this.eventsFiltered = [...this.events];
          this.totalRecords = this.events.length;
          this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
        }
      } else {
        console.error(response);
      }
    }, error => {
      console.error(<any>error);
    });
  }

  showMembersList(type, item) {
    this.selectedEvent.title = type === 'signed' ? this.labels[this.language].signedMembersList : this.labels[this.language].assistanceList;
    this.selectedEvent.name = item.name;
    this.selectedEvent.membersList = [];
    const filters = {
      eventId: item.id,
      showSignedMembers: type === 'signed' ? true : false
    };

    this.userEventsService.getMembersList(filters).subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        this.loading = false;

        if (this.response.totalRecords > 0) {
          this.selectedEvent.membersList = this.response.data.map(item => {
            item.name = item.member_full_name.toLowerCase();
            item.eventCodes = item.event_member_codes.split(',').map(code => {
              return {
                code,
                url: `https://ficha.vip/ficha.php?id=${code}&c=1`
              }
            });
            return item;
          });
          $('#listing').modal('toggle');
        }
      } else {
        console.error(response);
      }
    }, error => {
      console.error(<any>error);
    });
  }

  filterResults(value = this.keywords) {
    if (!this.events) {
      return;
    }

    if (!value) {
      this.eventsFiltered = this.events;
      return;
    }

    value = value.toLowerCase();
    this.eventsFiltered = null;
    this.eventsFiltered = [...this.events];
    if (this.eventType === 0) {
      this.eventsFiltered = this.eventsFiltered.filter(item => {
        const eventDate = item.start_date.split('-');
        return (item.name.toLowerCase().includes(value) 
          || item.type.toLowerCase().includes(value) 
          || item.start_date.toLowerCase().includes(value)
          || item.start_date_formated.toLowerCase().includes(value) 
          || this.labels[this.language].monthsLong.findIndex(month => month.toLowerCase().includes(value)) === Number.parseInt(eventDate[1]) - 1
        )
      });
    } else {
      const wordsList = value.split(' ');
      const  filteredResults = this.eventsFiltered.filter(item => {
        const agreements = item.agreements.join('.:.')
        return wordsList.some(word => item.name.toLowerCase().indexOf(word.toLowerCase()) >= 0)
          || wordsList.some(word => agreements.toLowerCase().indexOf(word.toLowerCase()) >= 0)
      });

      this.eventsFiltered = filteredResults.map(item => {
        const regEx = new RegExp(`(${ wordsList.join('|') })`, 'gi');
        const agreements = item.agreements.join('.:.')
        item.nameHighlighted = item.name.replace(regEx, `<span class="highlight">$1</span>`);
        const agreementsHighlighted = agreements.replace(regEx, `<span class="highlight">$1</span>`);
        item.agreementsHighlighted = agreementsHighlighted.split('.:.');
        return item;
      });
    }
  }

  toggleDetails(item) {
    item.showDetails = !item.showDetails;
  }

}
