import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/storage/storage.service';
import { PropertiesService } from 'src/app/properties/properties.service'
import { Property } from 'src/app/properties/property';
import { User } from 'src/app/login/user';
import { Router } from '@angular/router';
import { NavSideBarService } from 'src/app/nav-side-bar/nav-side-bar.service';

@Component({
  selector: 'app-user-properties',
  templateUrl: './user-properties.component.html',
  styleUrls: ['./user-properties.component.scss']
})
export class UserPropertiesComponent implements OnInit {
  labels: any;
  language: string;
  response: any;
  loading: boolean;
  properties: Array<Property>;
  totalRecords: number;
  totalPages: number;
  currentPage: number;
  itemsPerPage: number;
  pagesPerBlock: number;
  keywords: string;
  currencyTypes: object;
  imagesPath: string;
  user: User;

  constructor(
    private _router: Router,
    private navSideBarService: NavSideBarService,
    private storageService: StorageService,
    private propertiesService: PropertiesService
  ) {
    this.labels = {
      english: {
        title: 'Properties List',
        loading: 'Loading',
        keywords: 'Keywords',
        noResults: 'No property matches with the given info',
        showDetails: 'Show Details',
        hideDetails: 'Hide Details',
        reference: 'Code',
        name: 'Title',
        description: 'Description',
        price: 'Price',
        photo: 'Photo',
        actions: 'Actions',
        agency: 'Agency',
        propertyType: 'Property Type',
        bedrooms: 'Bedrooms',
        bathrooms: 'Bathrooms',
        builtArea: 'Built Area',
        lotSize: 'Lot Size',
        warehouse: 'Warehouse',
        house: 'House',
        apartment: 'Apartment',
        study: 'Study',
        building: 'Building',
        hotel: 'Hotel',
        shop: 'Shop',
        office: 'Office',
        pentgarden: 'Pent Garden',
        penthouse: 'Pent House',
        lot: 'Lot',
        lot_urbanized: 'Lot Urbanized',
        garages: 'Parking',
        gym: 'Gym',
        pool: 'Pool',
        gate: 'Gate',
        editLink: 'edit-property'
      },
      spanish: {
        title: 'Lista de Propiedades',
        loading: 'Cargando',
        keywords: 'Palabras clave',
        noResults: 'Ninguna propiedad coincide con la información proporcionada',
        showDetails: 'Ver Detalles',
        hideDetails: 'Ocultar Detalles',
        reference: 'Ref.',
        name: 'Título',
        description: 'Descripción',
        price: 'Precio',
        photo: 'Foto',
        actions: 'Acciones',
        agency: 'Agencia',
        propertyType: 'Tipo de Propiedad',
        bedrooms: 'Recámaras',
        bathrooms: 'Baños',
        builtArea: 'Construcción',
        lotSize: 'Terreno',
        warehouse: 'Bodega',
        house: 'Casa',
        apartment: 'Departamento',
        study: 'Estudio',
        building: 'Edificio',
        hotel: 'Hotel',
        shop: 'Local',
        office: 'Oficina',
        pentgarden: 'Pent Garden',
        penthouse: 'Pent House',
        lot: 'Terreno no urbanizado',
        lot_urbanized: 'Terreno urbanizado',
        garages: 'Garages',
        gym: 'Gimnasio',
        pool: 'Alberca',
        gate: 'Caseta',
        editLink: 'editar-propiedad'
      }
    }
  }

  get filterResults() {
    const value = this.keywords ? this.keywords.toLowerCase() : null;

    if (!this.properties) {
      return;
    }

    if (!value) {
      return this.properties;
    }

    const filteredResults = this.properties.filter(item => {
      return (item.name.toLowerCase().includes(value) 
        || item.property_type_name.toLowerCase().includes(value) 
        || item.member_name.toLowerCase().includes(value) 
        || item.collaborator_name.toLowerCase().includes(value) 
        || item.description.toLowerCase().includes(value) 
        || item.company_name.toLowerCase().includes(value)
        || item.id.toString() === value
        || (item.keywords && item.keywords.toLowerCase().split(',').find(keyword => keyword.includes(value))))
    });
    return filteredResults;
  }

  ngOnInit() {
    this.storageService.language$.subscribe(language => this.language = language);
    this.user = this.storageService.getItem('session', 'user');
    this.imagesPath = this.propertiesService.imagesUrl;
    this.navSideBarService.setCurrentRoute(this._router.url, this.language);
    this.loading = true;
    this.totalRecords = 0;
    this.totalPages = 0;
    this.currentPage = 1;
    this.itemsPerPage = 10;
    this.pagesPerBlock = 7;
    this.currencyTypes = {
      "1": 'MXN',
      "2": 'USD'
    }

    this.propertiesService.getProperties({}).subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        this.loading = false;
        this.properties = [];

        if (this.response.totalRecords > 0) {
          this.properties = this.response.data.properties.map(item => {
            item.contactPhone = item.collaborator_phone ? item.collaborator_phone : (item.member_phone ? item.member_phone : item.company_phone);
            item.showDetails = false;
            item.property_type_name = this.labels[this.language][item.property_type_name.toLowerCase()];
            item.editable = this.user.user_type === 'Admin' && ['Super', 'Admin'].includes(this.user.user_type_group) || 
              (this.user.user_type === 'Admin' && this.user.user_type_group === 'Association' && this.user.association_id == item.association_id) || 
              (this.user.user_type !== 'Admin' && this.user.member_id == item.member_id);
            item.editableLink = item.editable ? `./${this.labels[this.language].editLink}/${item.id}` : ''
            return item;
          });

          this.totalRecords = this.response.totalRecords;
          this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);

        }
        
      } else {
        console.error(response);
      }
    }, error => {
      console.error(<any>error);
    });
  }

  filterResultsX(value) {
    if (!this.properties) {
      return;
    }

    if (!value) {
      return this.properties;
    }

    value = value.toLowerCase();

    const filteredResults = this.properties.filter(item => {
      return (item.name.toLowerCase().includes(value) 
        || item.property_type_name.toLowerCase().includes(value) 
        || item.member_name.toLowerCase().includes(value) 
        || item.collaborator_name.toLowerCase().includes(value) 
        || item.description.toLowerCase().includes(value) 
        || item.company_name.toLowerCase().includes(value))
    });
    return filteredResults;
  }

  toggleDetails(item) {
    item.showDetails = !item.showDetails;
  }
}
