import { Component, OnInit } from '@angular/core';
import { RequestsService } from './requests.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Property } from './../properties/property';
import { NavBarService } from './../nav-bar/nav-bar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from './../storage/storage.service';
import { ShareProperty } from '../properties/share-property';
import { GeneralService } from '../services/general.service';
import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {
  deviceInfo: any;
  response: any;
  loading: boolean;
  requests: Array<Property>;
  totalRecords: number;
  totalPages: number;
  itemsPerPage: number;
  pagesPerBlock: number;
  currentPage: number;
  labels: object;
  language: string;
  keywords: string;
  shareProperty: ShareProperty;
  currencyTypes: object;

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private requestsService: RequestsService,
    private navBarService: NavBarService,
    private deviceService: DeviceDetectorService,
    private generalService: GeneralService
  ) {
    this.labels = {
      english: {
        title: 'Requests',
        keywords: 'Keywords',
        loading: 'Loading',
        noResults: 'No request matches with the given info',
        warehouse: 'Warehouse',
        house: 'House',
        apartment: 'Apartment',
        study: 'Study',
        building: 'Building',
        hotel: 'Hotel',
        shop: 'Shop',
        office: 'Office',
        pentgarden: 'Pent Garden',
        penthouse: 'Pent House',
        lot: 'Lot',
        lot_urbanized: 'Lot Urbanized',
        viewDetails: 'View Details',
        detailsUrl: 'request/'
      },
      spanish: {
        title: 'Solicitudes',
        loading: 'Cargando',
        keywords: 'Palabras clave',
        noResults: 'Ninguna solicitud coincide con la información proporcionada',
        warehouse: 'Bodega',
        house: 'Casa',
        apartment: 'Departamento',
        study: 'Estudio',
        building: 'Edificio',
        hotel: 'Hotel',
        shop: 'Local',
        office: 'Oficina',
        pentgarden: 'Pent Garden',
        penthouse: 'Pent House',
        lot: 'Terreno no urbanizado',
        lot_urbanized: 'Terreno urbanizado',
        viewDetails: 'Ver Detalles',
        detailsUrl: 'solicitud/'
      }
    }
    this.totalRecords = 0;
    this.totalPages = 0;
    this.itemsPerPage = 12;
    this.pagesPerBlock = 7;
    this.shareProperty = null;
  }

  ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;
    this.currentPage = Number.parseInt(queryParams.page) || 1;
    this.keywords = queryParams.keywords || ''
    this.storageService.language$.subscribe(language => this.language = language);
    this.navBarService.setCurrentRoute(this._router.url, this.language);
    this.loading = true;
    this.currencyTypes = {
      "1": 'MXN',
      "2": 'USD'
    }

    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isDesktopDevice = this.deviceService.isDesktop();
    const whatsappBase = !isDesktopDevice ? 'https://api.whatsapp.com/send' : 'https://web.whatsapp.com/send';

    this.requestsService.getRequests().subscribe(response => {
      this.response = response;

      if (this.response.code === 200) {
        this.loading = false;
        this.requests = [];

        if (this.response.totalRecords > 0) {
          this.requests = [...this.response.data];
          this.requests.forEach(item => {
            const contactName = item.collaborator_name || item.member_name;
            const propertyType = this.labels[this.language][item.property_type_name.toLowerCase()];
            const phone = item.collaborator_phone
              ? item.collaborator_phone
              : item.member_phone || item.company_phone;
            const website = item.member_website || item.company_website || '';
            const message = `text=Hola, puedo ayudarte con tu solicitud publicada en http://ampicun.org/solicitud/${item.id}`;
            const whatsappMessage = `${whatsappBase}?phone=52${phone}&${message}`;
            item['contactName'] = this.generalService.capitalizeAndTrim(contactName);
            item['property_type_name'] = propertyType;
            item['contactPhone'] = phone;
            item['whatsappBase'] = whatsappBase;
            item['whatsappMessage'] = whatsappMessage;
            item['member_website'] = `${!website.includes('http') ? 'http://' : ''}${website}`;
          });
          this.totalRecords = this.response.totalRecords;
          this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
        }
      }
    });
  }

  filterRequests(value) {
    if (!this.requests) {
      return;
    }

    if (!value) {
      return this.requests;
    }

    value = value.toLowerCase();

    const filteredRequests = this.requests.filter(item => {
      return (item.name.toLowerCase().includes(value) 
        || item.property_type_name.toLowerCase().includes(value) 
        || item.description.toLowerCase().includes(value) 
        || item.company_name.toLowerCase().includes(value)
        || item.contactName.toLowerCase().includes(value)
        || item.id.toString() === value
        || (item.keywords && item.keywords.toLowerCase().split(',').find(keyword => keyword.includes(value))))
    });
    return filteredRequests;
  }

  onSahreLink(request) {
    const requestName = request.name.trim();
    const propertyType = request.property_type_name
      ? `%0A%0D*Tipo de Propiedad:* ${request.property_type_name}`
      : '';
    const currency = this.currencyTypes[request.currency_id];
    const phone = request.collaborator_phone ? request.collaborator_phone : (request.member_phone ? request.member_phone : request.company_phone);
    const requestPrice = request.price
      ? `%0A%0D*Presupuesto Máximo:* ${request.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${currency}`
      : '';
    const assistantName = this.generalService.capitalizeAndTrim(request.collaborator_name || '');
    const memberName = this.generalService.capitalizeAndTrim(request.member_name);
    const collaboratorName = assistantName && assistantName.toLowerCase() !== memberName.toLowerCase()
      ? `%0A_Agente:_ ${assistantName}`
      : '';
    const agentPhone = phone ? `%0A_Tel:_ ${phone}` : '';
    const agentName = request.listing_member_id ? `%0A%0D*Publicación realizada por:*%0A_Asesor:_ ${request.member_name}${collaboratorName}${agentPhone}` : '';
    const url = `https://ampicun.org/solicitud/${request.id}`;
    let attributes = '';

    if (request.attributes) {
      const builtArea = request.attributes.built_area
        ? `%0A%0D*Construcción:* ${request.attributes.built_area.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} m2`
        : '';
      const lotSize = request.attributes.lot_size
        ? `%0A%0D*Terreno:* ${request.attributes.lot_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} m2`
        : '';
      const bedrooms = request.attributes.beds
        ? `%0A%0D*Recámaras:* ${request.attributes.beds}`
        : '';
      const bathrooms = request.attributes.baths
        ? `%0A%0D*Baños:* ${request.attributes.baths}`
        : '';
      const garages = request.attributes.garages
        ? `%0A%0D*Garages:* ${request.attributes.garages}`
        : '';
      const gym = request.attributes.gym > 0
        ? `%0A%0D*Gimnasio:* Sí`
        : '';
      const pool = request.attributes.pool > 0
        ? `%0A%0D*Alberca:* Sí`
        : '';
      const gate = request.attributes.gate > 0
        ? `%0A%0D*Vigilancia:* Sí`
        : '';
      attributes = `${builtArea}${lotSize}${bedrooms}${bathrooms}${garages}${gym}${pool}${gate}`;
    }

    this.shareProperty = {
      body: `*_${requestName}_*%0A%0D${propertyType}%0A%0D*Referencia:* ${request.id}${requestPrice}${attributes}`,
      agentId: request.contact_id,
      memberId: request.listing_member_id,
      agentName: agentName,
      agencyName: request.company_name.toUpperCase(),
      detailsSheet: url,
      whatsappBase: request.whatsappBase,
      propertyUrl: request.property_url
    };
    $('#share').modal('toggle');
  }

  shareTo(appName) {
    if (!this.shareProperty) {
      return;
    }

    let url = '';
    // const agentInfo = `%26c=1`;
    this.shareProperty.body = appName !== 'whatsapp'
      ? this.shareProperty.body.replace(/\*/g, '').replace(/_/g, '')
      : this.shareProperty.body;
    this.shareProperty.agentName = appName !== 'whatsapp'
      ? this.shareProperty.agentName.replace(/\*/g, '').replace(/_/g, '')
      : this.shareProperty.agentName
    const sharePropertyAgent = `${this.shareProperty.agencyName}%0A%0D%0A%0D${this.shareProperty.body}%0A%0D${this.shareProperty.agentName}`;
    const sharePropertyMessage = sharePropertyAgent;
    // this.shareProperty.detailsSheet += agentInfo;

    switch (appName) {
      case 'whatsapp':
        url = `${this.shareProperty.whatsappBase}?text=${sharePropertyMessage}%0A%0D%0A%0D${this.shareProperty.detailsSheet}`;
        break;
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${this.shareProperty.detailsSheet}`;
        break;
      case 'twitter':
        url = `http://www.twitter.com/share?url=${this.shareProperty.detailsSheet}&text=${sharePropertyMessage}%0A%0D%0A%0D`;
        break;
    }

    window.open(url);
    $('#share').modal('toggle');
  }
}
