import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from './../login/user';
import { LoginService } from './../login/login.service';
import { NavSideBarService } from './nav-side-bar.service';
import { environment } from './../../environments/environment';
import { sharedUrls } from './../shared/base-url';
import { StorageService } from './../storage/storage.service';

@Component({
  selector: 'app-nav-side-bar',
  templateUrl: './nav-side-bar.component.html',
  styleUrls: ['./nav-side-bar.component.scss']
})
export class NavSideBarComponent implements OnInit {
  labels: any;
  content: any;
  navbarOpen: boolean;
  isEnglish: boolean;
  language: string;
  response: any;
  user: User;
  imagesPath: string;
  associationLogo: string;

  constructor(
    private _router: Router,
    private storageService: StorageService,
    private navSideBarService: NavSideBarService,
    private loginService: LoginService
  ) {
    this.storageService.getConfig();
    this.storageService.language$.subscribe(language => this.language = language);

    const baseUrl = environment.baseUrl;
    this.imagesPath = environment.imagesUrl;
    this.labels = {
      english: {
        navList: [
          {
            id: 1,
            code: 'dashboards',
            name: 'Dashboards',
            icon: {
              prefix: 'fas',
              name: 'tachometer-alt'
            },
            link: '/home',
            roles: [],
            children: []
          },
          {
            id: 2,
            code: 'users',
            name: 'Users',
            icon: {
              prefix: 'fas',
              name: 'user-shield'
            },
            roles: [
              'Super',
              'Admin',
              'Association'
            ],
            children: [
              {
                nameShort: 'NU',
                nameLong: 'New User',
                link: '/new-user',
                roles: []
              },
              {
                nameShort: 'UL',
                nameLong: 'Users List',
                link: '/users-list',
                roles: []
              }
            ]
          },
          {
            id: 3,
            code: 'members',
            name: 'Members',
            icon: {
              prefix: 'fas',
              name: 'users'
            },
            roles: [],
            children: [
              {
                nameShort: 'NM',
                nameLong: 'New Member',
                link: '/new-member',
                roles: [
                  'Super',
                  'Admin',
                  'Association'
                ]
              },
              {
                nameShort: 'ML',
                nameLong: 'Members List',
                link: '/members-list',
                roles: []
              }
            ]
          },
          {
            id: 4,
            code: 'properties',
            name: 'Properties',
            icon: {
              prefix: 'fas',
              name: 'building'
            },
            roles: [],
            children: [
              {
                nameShort: 'NP',
                nameLong: 'New Property',
                link: '/new-property',
                roles: [
                  'Member'
                ]
              },
              {
                nameShort: 'PL',
                nameLong: 'Properties List',
                link: '/properties-list',
                roles: []
              }
            ]
          },
          {
            id: 5,
            code: 'requests',
            name: 'Requests',
            icon: {
              prefix: 'fas',
              name: 'bookmark'
            },
            roles: [],
            children: [
              {
                nameShort: 'NR',
                nameLong: 'New Request',
                link: '/new-request',
                roles: [
                  'Member'
                ]
              },
              {
                nameShort: 'RL',
                nameLong: 'Requests List',
                link: '/requests-list',
                roles: []
              }
            ]
          },
          {
            id: 6,
            code: 'events',
            name: 'Events',
            icon: {
              prefix: 'fas',
              name: 'calendar-day'
            },
            roles: [],
            children: [
              {
                nameShort: 'NE',
                nameLong: 'New Event',
                link: '/new-event',
                roles: [
                  'Super',
                  'Admin'
                ]
              },
              {
                nameShort: 'EL',
                nameLong: 'Events List',
                link: '/events-list',
                roles: []
              }
            ]
          }
        ],
        linkHome: '/home',
        linkProfile: '/user-profile',
        myProfileShort: 'MP',
        myProfileLong: 'My Profile',
        signOutShort: 'SO',
        signOutLong: 'Sign Out',
        logout: 'Logout',
        languageTitle: 'Español'
      },
      spanish: {
        navList: [
          {
            id: 1,
            code: 'dashboards',
            name: 'Estadísticas',
            icon: {
              prefix: 'fas',
              name: 'tachometer-alt'
            },
            link: '/inicio',
            roles: [],
            children: []
          },
          {
            id: 2,
            code: 'users',
            name: 'Usuarios',
            icon: {
              prefix: 'fas',
              name: 'user-shield'
            },
            roles: [
              'Super',
              'Admin',
              'Association'
            ],
            children: [
              {
                nameShort: 'NU',
                nameLong: 'Nuevo Usuario',
                link: '/nuevo-usuario',
                roles: [
                  'Super',
                  'Admin',
                  'Association'
                ]
              },
              {
                nameShort: 'LU',
                nameLong: 'Lista de Usuarios',
                link: '/listado-usuarios',
                roles: [
                  'Super',
                  'Admin',
                  'Association'
                ]
              }
            ]
          },
          {
            id: 3,
            code: 'members',
            name: 'Socios',
            icon: {
              prefix: 'fas',
              name: 'users'
            },
            roles: [],
            children: [
              {
                nameShort: 'NS',
                nameLong: 'Nuevo Socio',
                link: '/nuevo-socio',
                roles: [
                  'Super',
                  'Admin',
                  'Association',
                ]
              },
              {
                nameShort: 'LS',
                nameLong: 'Lista de Socios',
                link: '/listado-socios',
                roles: []
              }
            ]
          },
          {
            id: 4,
            code: 'properties',
            name: 'Propiedades',
            icon: {
              prefix: 'fas',
              name: 'building'
            },
            roles: [],
            children: [
              {
                nameShort: 'NP',
                nameLong: 'Nueva Propiedad',
                link: '/nueva-propiedad',
                roles: [
                  'Member'
                ]
              },
              {
                nameShort: 'LP',
                nameLong: 'Lista de Propiedades',
                link: '/listado-propiedades',
                roles: []
              }
            ]
          },
          {
            id: 5,
            code: 'requests',
            name: 'Solicitudes',
            icon: {
              prefix: 'fas',
              name: 'bookmark'
            },
            roles: [],
            children: [
              {
                nameShort: 'NS',
                nameLong: 'Nueva Solicitud',
                link: '/nueva-solicitud',
                roles: [
                  'Member'
                ]
              },
              {
                nameShort: 'LS',
                nameLong: 'Lista de Solicitudes',
                link: '/listado-solicitudes',
                roles: []
              }
            ]
          },
          {
            id: 6,
            code: 'events',
            name: 'Eventos',
            icon: {
              prefix: 'fas',
              name: 'calendar-day'
            },
            roles: [],
            children: [
              {
                nameShort: 'NE',
                nameLong: 'Nuevo Evento',
                link: '/nuevo-evento',
                roles: [
                  'Super',
                  'Admin'
                ]
              },
              {
                nameShort: 'LE',
                nameLong: 'Lista de Eventos',
                link: '/listado-eventos',
                roles: []
              }
            ]
          }
        ],
        linkHome: '/inicio',
        linkProfile: '/perfil-usuario',
        myProfileShort: 'MP',
        myProfileLong: 'Mi Perfil',
        signOutShort: 'CS',
        signOutLong: 'Cerrar Sesión',
        logout: 'Cerrar Sesión',
        languageTitle: 'English'
      },
    };
    this.navbarOpen = false;
  }

  ngOnInit() {
    this.labels.english.navList.shift();
    this.labels.spanish.navList.shift();

    this.navbarOpen = false;
    this.user = this.storageService.getItem('session', 'user');
    this.user.user_name = this.user.user_name.toLowerCase();
    this.content = this.labels[this.language];
    this.associationLogo = this.user.association_logo ? `${this.imagesPath}${this.user.association_logo}` : './../../assets/logo-ampicancun.png'
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  changeLanguage() {
    const currentLanguage = this.language;
    this.storageService.changeLanguage();
    this.storageService.language$.subscribe(language => this.language = language);
    this.navSideBarService.setPageProperties(currentLanguage, this.language);
    this.content = this.labels[this.language]
  }

  signOut() {
    this.loginService.logout();
  }
}
