import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/login/user';
import { Router } from '@angular/router';
import { NavSideBarService } from 'src/app/nav-side-bar/nav-side-bar.service';
import { StorageService } from 'src/app/storage/storage.service';
import { UserMembersService } from './user-members.service';
import { Member } from './member';
import { Association } from './association';
import { MemberType } from './member-type';

@Component({
  selector: 'app-user-members',
  templateUrl: './user-members.component.html',
  styleUrls: ['./user-members.component.scss']
})
export class UserMembersComponent implements OnInit {
  labels: any;
  language: string;
  user: User;
  imagesPath: string;
  response: any;
  innerResponse: any;
  loading: boolean;
  totalRecords: number;
  totalPages: number;
  currentPage: number;
  itemsPerPage: number;
  pagesPerBlock: number;
  membersList: Array<Member>;
  collaboratorsList: Array<Member>;
  members: Array<Member>;
  membersByGroup: Array<any>;
  memberTypes: Array<MemberType>;
  associations: Array<Association>;
  associationId: number;
  keywords: string;

  constructor(
    private _router: Router,
    private navSideBarService: NavSideBarService,
    private storageService: StorageService,
    private userMembersService: UserMembersService
  ) {
    this.labels = {
      english: {
        title: 'Members List',
        allAssociations: 'All Associations',
        noAssociation: 'Without Association',
        keywords: 'Keywords',
        photo: 'Photo',
        agent: 'Agent',
        company: 'Agency',
        type: 'Type',
        category: 'Category',
        status: 'Status',
        details: 'Details',
        member: 'Member',
        life: 'Life',
        affiliate: 'Affiliate',
        guest: 'Guest',
        collaborator: 'Collaborator',
        advisor: 'Advisor',
        isActive: 'Active',
        isInactive: 'Inactive',
        phone: 'Phone',
        showDetails: 'Show Details',
        hideDetails: 'Hide Details'
      },
      spanish: {
        title: 'Lista de Socios',
        allAssociations: 'Todas las Asociaciones',
        noAssociation: 'Sin Asociación',
        keywords: 'Palabras clave',
        photo: 'Foto',
        agent: 'Agente',
        company: 'Agencia',
        type: 'Tipo',
        category: 'Categoría',
        status: 'Estatus',
        details: 'Detalles',
        member: 'Socio',
        life: 'Vitalicio',
        affiliate: 'Afiliado',
        guest: 'Invitado',
        collaborator: 'Colaborador',
        advisor: 'Asesor',
        isActive: 'Activo(a)',
        isInactive: 'Inactivo(a)',
        phone: 'Teléfono',
        showDetails: 'Ver Detalles',
        hideDetails: 'Ocultar Detalles'
      }
    }
  }

  ngOnInit() {
    this.storageService.language$.subscribe(language => this.language = language);
    this.user = this.storageService.getItem('session', 'user');
    this.navSideBarService.setCurrentRoute(this._router.url, this.language);
    this.imagesPath = this.userMembersService.imagesUrl;
    this.associationId = this.user.association_id || 0;
    this.loading = true;
    this.totalRecords = 0;
    this.totalPages = 0;
    this.currentPage = 1;
    this.itemsPerPage = 10;
    this.pagesPerBlock = 7;
    this.members = [];
    this.membersList = [];
    this.collaboratorsList = [];
    this.memberTypes = [];
    this.associations = [];

    this.userMembersService.getMemberTypes().subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        if (this.response.totalRecords > 0) {
          this.memberTypes = [...this.response.data];
        }
      } else {
        console.error(response);
      }
    }, error => {
      console.error(<any>error);
    });

    this.userMembersService.getAssociationsList().subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        if (this.response.totalRecords > 0) {
          this.associations = [...this.response.data];
        }
      } else {
        console.error(response);
      }
    }, error => {
      console.error(<any>error);
    });

    this.getMembersList();
  }

  getMembersList () {
    this.loading = true;
    this.currentPage = 1;
    this.members = [];
    this.membersList = [];
    this.collaboratorsList = [];
    const filters = this.associationId < 0 ? {} : {
      associationId: this.associationId
    };
  
    this.userMembersService.getMembersList(filters).subscribe(response => {
      this.response = response;
      if (this.response.code === 200) {
        this.loading = false;

        if (this.response.totalRecords > 0) {
          this.membersList = this.response.data.map(item => {
            item.agent_short_name = item.agent_short_name.toLowerCase();
            item.agent_full_name = item.agent_full_name.toLowerCase();
            item.member_name = item.member_name.toLowerCase();
            item.agent_type = this.labels[this.language][item.agent_type.toLowerCase()];
            item.agent_category = this.labels[this.language][item.agent_category.toLowerCase()];
            item.showDetails = false;
            item.agent_photo = item.agent_photo || 'members/no-photo.jpg';
            item.agent_phone = item.agent_phone1 ? item.agent_phone1 : (item.agent_phone2 ? item.agent_phone2 : item.agent_mobile);
            return item;
          });
          this.members = [...this.membersList];
          this.totalRecords = this.members.length;
          this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);

          this.userMembersService.getCollaboratorsList(filters).subscribe(response => {
            this.innerResponse = response;
            if (this.innerResponse.code === 200) {
              this.loading = false;

              if (this.innerResponse.totalRecords > 0) {
                this.collaboratorsList = this.innerResponse.data.map(item => {
                  item.agent_short_name = item.agent_short_name.toLowerCase();
                  item.agent_full_name = item.agent_full_name.toLowerCase();
                  item.member_name = item.member_name.toLowerCase();
                  item.agent_type = this.labels[this.language][item.agent_type.toLowerCase()];
                  item.agent_category = this.labels[this.language][item.agent_category.toLowerCase()];
                  item.showDetails = false;
                  item.agent_photo = item.agent_photo || 'members/no-photo.jpg';
                  item.agent_phone = item.agent_phone1 ? item.agent_phone1 : (item.agent_phone2 ? item.agent_phone2 : item.agent_mobile);
                  return item;
                });

                this.members = [...this.members, ...this.collaboratorsList];
                this.members.sort((a, b) => a.agent_full_name > b.agent_full_name ? 1 : (a.agent_full_name < b.agent_full_name ? -1 : 0));
                this.totalRecords = this.members.length;
                this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
              }
            } else {
              console.error(response);
            }
          }, error => {
            console.error(<any>error);
          });
        }
      } else {
        console.error(response);
      }
    }, error => {
      console.error(<any>error);
    });
  }

  filterResults(value) {
    if (!this.members) {
      return;
    }

    if (!value) {
      return this.members;
    }

    value = value.toLowerCase();

    const filteredResults = this.members.filter(item => {
      return (item.agent_type.toLowerCase().includes(value) 
        || item.company_name.toLowerCase().includes(value) 
        || item.agent_full_name.toLowerCase().includes(value)
        || item.member_name.toLowerCase().includes(value) 
        || item.agent_email.toLowerCase().includes(value)
        || (this.user.association_id === item.association_id && (
          item.member_number.toLowerCase().includes(value)
        ))
      )
    });
    return filteredResults;
  }

  toggleDetails(item) {
    item.showDetails = !item.showDetails;
  }
}
