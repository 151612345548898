import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor() { }

  capitalize(text) {
    return text.split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    }).join(' ');
  }

  fullTrim(text) {
    return text.trim().replace(/  /g, ' ');
  }

  capitalizeAndTrim(text) {
    text = text.toLowerCase();
    const capitalize = text.split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    }).join(' ');

    return capitalize.trim().replace(/  /g, ' ');
  }
}
