import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import { StorageService } from '../../storage/storage.service';
import { Property } from './../../properties/property';
import { Agent } from '../../agents/agent';
import { RequestsService } from './../requests.service';
import { NavBarService } from '../../nav-bar/nav-bar.service';
import { Title, Meta } from '@angular/platform-browser';
import { MetafrenzyService } from 'ngx-metafrenzy';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {
  id: number;
  agentId: number;
  labels: object;
  language: string;
  response: any;
  loading: boolean;
  request: Property;
  agent: Agent;
  currencyTypes: object;
  imagesPath: string;
  photos: Array<string>;
  goBackUrl: string;
  agentType: string;

  constructor(
    private _router: Router,
    private storageService: StorageService,
    private RequestsService: RequestsService,
    private navBarService: NavBarService,
    private route: ActivatedRoute,
    private pageTitle: Title,
    private pageMeta: Meta,
    private readonly metafrenzyService: MetafrenzyService
  ) {
    this.labels = {
      english: {
        title: 'Available Requests',
        noResults: 'No request matches with the given info',
        reference: 'Code',
        requestAddress: 'Preferred Location',
        requestSpecialFeature: 'Special Feature',
        propertyType: 'Property Type',
        bedrooms: 'Bedrooms',
        bathrooms: 'Bathrooms',
        builtArea: 'Built Area',
        lotSize: 'Lot Size',
        warehouse: 'Warehouse',
        house: 'House',
        apartment: 'Apartment',
        study: 'Study',
        building: 'Building',
        hotel: 'Hotel',
        shop: 'Shop',
        office: 'Office',
        pentgarden: 'Pent Garden',
        penthouse: 'Pent House',
        lot: 'Lot',
        lot_urbanized: 'Lot Urbanized',
        garages: 'Parking',
        gym: 'Gym',
        pool: 'Pool',
        gate: 'Surveillance',
        yes: 'Yes',
        no: 'No',
        loading: 'Loading...',
        viewDetails: 'View Details',
        propertiesUrl: '/properties',
        goBack: 'Available Properties',
        listedBy: 'Published by',
        features: 'Features',
        member: 'Member',
        agent: 'Agent'
      },
      spanish: {
        title: 'Solicitudes Disponibles',
        noResults: 'Ninguna solicitud coincide con la información proporcionada',
        reference: 'Ref.',
        requestAddress: 'Ubicación Preferente',
        requestSpecialFeature: 'Característica Especial',
        propertyType: 'Tipo de Propiedad',
        bedrooms: 'Recámaras',
        bathrooms: 'Baños',
        builtArea: 'Construcción',
        lotSize: 'Terreno',
        warehouse: 'Bodega',
        house: 'Casa',
        apartment: 'Departamento',
        study: 'Estudio',
        building: 'Edificio',
        hotel: 'Hotel',
        shop: 'Local',
        office: 'Oficina',
        pentgarden: 'Pent Garden',
        penthouse: 'Pent House',
        lot: 'Terreno no urbanizado',
        lot_urbanized: 'Terreno urbanizado',
        garages: 'Garages',
        gym: 'Gimnasio',
        pool: 'Alberca',
        gate: 'Vigilancia',
        yes: 'Sí',
        no: 'No',
        loading: 'Cargando...',
        viewDetails: 'Ver Detalles',
        propertiesUrl: '/solicitudes',
        goBack: 'Solicitudes Disponibles',
        listedBy: 'Publicado por',
        features: 'Características',
        member: 'Asesor',
        agent: 'Agente'
      }
    };
  }

  ngOnInit() {
    this.storageService.language$.subscribe(language => this.language = language);
    this.navBarService.setCurrentRoute(this._router.url, this.language);
    const previousUrl = document.referrer;
    const propertiesUrl = this.labels[this.language].propertiesUrl;
    this.goBackUrl = previousUrl && previousUrl.includes(propertiesUrl) ? previousUrl : propertiesUrl;
    this.currencyTypes = {
      "1": 'MXN',
      "2": 'USD'
    }
    this.loading = true;
    this.photos = [];
    this.request = null;
    this.agent = null;
    this.imagesPath = this.RequestsService.imagesUrl;
    this.id = Number.parseInt(this.route.snapshot.paramMap.get("requestId"));
    this.RequestsService.getRequest(this.id).subscribe(response => {
      this.response = response;
      this.loading = false;
      
      if (this.response.code === 200) {
        if (this.response.record_found) {
          this.request = this.response.data.request[0];
          this.request.property_type_name = this.request.property_type_name.toLocaleLowerCase();
          const memberName = this.request.member_name.replace(/\s+/g, ' ').trim();
          const agentName = this.request.collaborator_name.replace(/\s+/g, ' ').trim();
          const photo = this.request.member_photo || this.request.company_logo;
          const website = this.request.member_website || this.request.company_website || '';
          const email = agentName
            ? this.request.collaborator_email
            : this.request.member_email || '';
          const phone = agentName
            ? this.request.collaborator_phone
            : (this.request.member_phone || '');
          this.agent = {
            member: memberName.toLowerCase(),
            name: agentName.toLowerCase(),
            photo: photo,
            website: website,
            email: email,
            phone: phone
          };
          this.pageTitle.setTitle(this.request.name);
          const metaDescription = this.pageMeta.getTags('name=description');
          metaDescription[0].content = this.request.description;
          const author = this.pageMeta.getTags('name=author');
          author[0].content = this.request.description;
          this.metafrenzyService.setMetaTag('author', 'website');
          this.metafrenzyService.setMetaTag('og:type', 'website');
          this.metafrenzyService.setMetaTag('og:title', this.request.name);
          this.metafrenzyService.setMetaTag('og:description', this.request.description);
          this.metafrenzyService.setMetaTag('og:image:type', 'image/jpeg');
          this.metafrenzyService.setMetaTag('og:image:width', '300');
          this.metafrenzyService.setMetaTag('og:image:height', '300');
        }
      }
    });
  }
}
