import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { sharedUrls } from '../../../shared/base-url';

@Injectable({
  providedIn: 'root'
})
export class UserDashboardService {
  apiUrl: string;

  constructor(private httpClient: HttpClient
  ) {
    const baseUrl = environment.baseUrl;
    this.apiUrl = `${baseUrl}${sharedUrls.apiUrl}`;
  }

  getDashboards(filters) {
    const url = `${this.apiUrl}/user-dashboards`;
    let json = JSON.stringify(filters);
    let params = new HttpParams().set('json', json);

    return this.httpClient.get(url, { params: params });
  }
}
